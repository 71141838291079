// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[2]!../../../node_modules/ag-grid-community/styles/ag-grid.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[2]!../../../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* You can add global styles to this file, and also import other style files */
.skelton-loading-placeholder {
  width: 180px;
  height: 17px;
  border-radius: 4px;
  opacity: 0.8;
  background: var(--Surface-Disabled, rgba(78, 91, 109, 0.3));
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}`, "",{"version":3,"sources":["webpack://./projects/dashboard-reporting/src/styles.scss"],"names":[],"mappings":"AAAA,8EAAA;AAIA;EACI,YAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,2DAAA;EACA,mCAAA;UAAA,2BAAA;AAAJ","sourcesContent":["/* You can add global styles to this file, and also import other style files */\n// In your styles.scss or styles.css\n@import \"~ag-grid-community/styles/ag-grid.css\";\n@import \"~ag-grid-community/styles/ag-theme-alpine.css\";\n.skelton-loading-placeholder {\n    width: 180px;\n    height: 17px;\n    border-radius: 4px;\n    opacity: 0.8;\n    background: var(--Surface-Disabled, rgba(78, 91, 109, 0.3));\n    backdrop-filter: blur(10px);\n  }\n  \n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
