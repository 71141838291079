<div class="position-relative">
  <div class="canvas-viewer-wrap">
    <div class="canvas-viewer-container">
      <div class="image-viewer">
        <div
          id="canvas-parent"
          ref="canvas-parent-wrap"
          class="image-canvas"
          #thumbCanvasContainer>
          <ng-container>
            <div [id]="stageProperty?.id"></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="imageStatus === 'loading'" class="loading-placeholder"></div>
  <div *ngIf="imageStatus === 'failed'" class="error-placeholder">
    <span>Unable To Load Image</span>
  </div>
</div>
