@if (showDashboard) {
  <div class="left-panel-wrapper" [style]="customStyle.componentStyles">
    <div class="left-panel">
      @if (visiableComponents.header) {
        <div class="heading">
          <img
            [src]="'/images/collapse-icon.svg' | assetUrl"
            alt="collapse-arrow"
            (click)="hideDashboardChartsPanel()"
            class="collapse-icon"
          />
          <div class="title">Dashboard</div>
        </div>
      }
      @if (visiableComponents.filters) {
        <div class="filter-section-heading">Findings By Month</div>
        <div class="d-flex justify-content-start date-filter-section">
          <div class="search-item-box">
            <label>Start Date</label>
            <input
              type="date"
              class="search-item"
              placeholder="Search"
              [value]="dashboardFilters.startDate"
              (change)="onDateChange($event, 'startDate')"
            />
            <img
              [src]="'/images/cross-icon.svg' | assetUrl"
              alt="search-icon"
              class="search-icon"
              *ngIf="dashboardFilters.startDate"
              (click)="onDateChange('', 'startDate')"
            />
          </div>
          <div class="search-item-box">
            <label>End Date</label>
            <input
              type="date"
              class="search-item"
              placeholder="Search"
              [value]="dashboardFilters.endDate"
              (change)="onDateChange($event, 'endDate')"
            />
            <img
              [src]="'/images/cross-icon.svg' | assetUrl"
              alt="search-icon"
              class="search-icon"
              *ngIf="dashboardFilters.endDate"
              (click)="onDateChange('', 'endDate')"
            />
          </div>
        </div>
      }
      @if (visiableComponents.findingsTotal) {
        <div class="d-flex">
          <div class="w-50">
            <app-findings-chart
              [findingsChartOptions]="totalFindingsChartOptions"
              [findingsCount]="_totalFindingsChartData"
              :key="selectedMissionId"
            ></app-findings-chart>
          </div>
          <div class="w-53">
            <app-findings-chart
              [findingsChartOptions]="criticalFindingsChartOptions"
              [findingsCount]="_criticalFindingsChartData"
            ></app-findings-chart>
          </div>
        </div>
      }
      @if (visiableComponents.findingByPriority) {
        <div class="chart-container">
          <span class="chart-title">Findings by Priority</span>
          @if (
            _severityFindingsChartData && _severityFindingsChartData.length
          ) {
            <donut-chart
              [dataSource]="_severityFindingsChartData"
              [chartTitle]="
                helperService
                  .getTotalValues(_severityFindingsChartData)
                  .toString()
              "
              [chartOptions]="priorityChartConfig"
            ></donut-chart>
          } @else {
            <span class="empty-placeholder">No Data Available</span>
          }
        </div>
      }
      @if (visiableComponents.findingsByComponent) {
        <div class="chart-container">
          <span class="chart-title">Findings by Components</span>
          @if (_statsByComponents && _statsByComponents.length) {
            <pie-chart
              [dataSource]="_statsByComponents"
              [chartOptions]="priorityChartConfig"
            ></pie-chart>
          } @else {
            <span class="empty-placeholder">No Data Available</span>
          }
        </div>
      }
      @if (visiableComponents.findingType) {
        <div [style]="customStyle.findingsTypeStyles" class="half-donut-chart-container">
          <span class="chart-title">Findings Type</span>
          @if (_findingsByTypeChartData && _findingsByTypeChartData.length) {
            <app-single-bar-chart
              [dataSource]="_findingsByTypeChartData"
              [chartOptions]="findingsTypeChartConfig"
              [chartTitle]="
                this.helperService
                  .getTotalValues(_findingsByTypeChartData)
                  .toString()
              "
            ></app-single-bar-chart>
          } @else {
            <span class="empty-placeholder">No Data Available</span>
          }
        </div>
      }
      @if (visiableComponents.findingsByMonth && !selectedMissionId) {
        <div class="line-chart-container">
          <span class="chart-title">Findings By Month</span>
          @if (_findingsByMonthChartData && _findingsByMonthChartData.length) {
            <app-area-chart
              [dataSource]="_findingsByMonthChartData"
            ></app-area-chart>
          } @else {
            <span class="empty-placeholder">No Data Available</span>
          }
        </div>
      }
      @if (visiableComponents.findingsByLaterals && !selectedMissionId) {
        <div class="scatter-chart-container">
          <span class="chart-title">Findings By Laterals</span>
          @if (_lateralsChartData && _lateralsChartData.length) {
            <app-scatter-chart
              [dataSource]="_lateralsChartData"
            ></app-scatter-chart>
          } @else {
            <span class="empty-placeholder">No Data Available</span>
          }
        </div>
      }
      @if (visiableComponents.findingsByRegionalOffice && !selectedMissionId) {
        <div class="scatter-chart-container">
          <span class="chart-title">Findings By Regional Office</span>
          @if (_regionalChartData && _regionalChartData.length) {
            <app-scatter-chart
              [dataSource]="_regionalChartData"
            ></app-scatter-chart>
          } @else {
            <span class="empty-placeholder">No Data Available</span>
          }
        </div>
      }
    </div>
  </div>
  @if (selectedIcon === UTMEventsIcons.MISSION) {
    <div class="show-panel-btn-wrapper">
      <button (click)="showDashboardChartsPanel()" class="show-panel-btn">
        Show Panel
      </button>
    </div>
  }
}
