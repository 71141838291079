import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MissionDetails } from '../../interfaces';
import { Routes } from '../routes/api-routes';
@Injectable({
  providedIn: 'root',
})
export class MissionService {
  constructor(private httpClient: HttpClient) {}

  getMissionDetails(missionId: string) {
    return this.httpClient
      .get<MissionDetails>(`${Routes.GET_MISSION_DETAILS}/${missionId}`, {})
      .pipe();
  }

  getAllMissionsList(searchTerm?: string, nextCursor?: string) {
    return this.httpClient
      .get<any>(`${Routes.GET_MISSIONS_LIST}`, {
        params: {
          searchTerm: searchTerm ?? ' ',
          nextCursor: nextCursor ?? '',
        },
      })
      .pipe();
  }
}
