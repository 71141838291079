import { Component } from '@angular/core';
import { USER_PERMISSIONS } from './shared/directives/user-permissions/user-permission.const';
import { DASHBOARD_RIGHT_PANEL_COMPONENTS } from './shared/constants/index.constants';


@Component({
  selector: 'app-dashboard-reporting',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'fe-dashboard-reporting';
  USER_PERMISSIONS = USER_PERMISSIONS;
  dashboardRightPanelComponents = DASHBOARD_RIGHT_PANEL_COMPONENTS

  isAtRoot() {
    return window.location.pathname == '/'
  }

}
