import { Component } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ChartData, DonutChartOptions } from 'projects/dashboard-reporting/src/app/shared/interfaces';
import { DroneUsageChartService } from 'projects/dashboard-reporting/src/app/shared/services/charts/drone-usage-chart.service';
import { MissionChartService } from 'projects/dashboard-reporting/src/app/shared/services/charts/mission-chart.service';
import { HelperService } from 'projects/dashboard-reporting/src/app/shared/services/helpers/helper.service';

@Component({
  selector: 'app-mission-charts-panel',
  templateUrl: './mission-charts-panel.component.html',
  styleUrls: ['./mission-charts-panel.component.scss'],
})
export class MissionChartsPanelComponent {
  showMissionPanel = false;
  selectedIcon = '';
  _missionChartData: ChartData[] = [];
  chartConfig!: DonutChartOptions;
  _userUsageChartData: ChartData[] = [];

  constructor(
    private missionChartService: MissionChartService,
    private droneUsageChartServive: DroneUsageChartService,
    private helperService: HelperService
  ) {}

  showMissionChartsPanel() {
    this.showMissionPanel = true;
  }

  hideMissionChartsPanel() {
    this.showMissionPanel = false;
  }

  ngOnInit() {
    window.addEventListener('dronos:utm:sidebar-click', (event: any) => {
      this.selectedIcon = event.detail.icon;
      if (event.detail.icon == 'mission') this.showMissionPanel = true;
    });
    this.getMissionChartData();
    this.chartConfig = {
      tooltipText: 'Number of Missions',
      labelFontSize:'11px'
    };
  }

  async getMissionChartData() {
    const data = await lastValueFrom(
      this.missionChartService.getMissionChartData()
    );
    this._missionChartData = this.helperService.sortArrayInDescendingOrder(data);
    const data2 = await lastValueFrom(
      this.droneUsageChartServive.getDroneUsageChartData()
    );
    this._userUsageChartData =
      this.helperService.sortArrayInDescendingOrder(data2);
  }

  getChartTitle(dataSource: ChartData[]) {
    return `${this.helperService.getTotalValues(dataSource)} missions`.length >
      12
      ? `${this.helperService.getTotalValues(dataSource)} missions`.slice(
          0,
          12
        ) + '...'
      : `${this.helperService.getTotalValues(dataSource)} missions`;
  }
}
