import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent {
  selectedRows: any = [];

  @Input() columns!: ColDef[];
  @Input() dataSource: any = [];
  @Input() loading: boolean = false;
  @Input() totalRecords?: number;
  @Input() currentPage: number;
  @Output() onPageChange = new EventEmitter<number>();
  @Output() onRowSelection = new EventEmitter<any>();

  @ViewChild('dataTable') grid!: AgGridAngular;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource'] && !changes['dataSource'].firstChange) {
      this.grid.api.forEachNode((node) => {
        if (this.selectedRows.includes(node.data)) node.setSelected(true);
      });
    }
  }

  pageChange(event: any) {
    this.onPageChange.emit(event);
  }

  updateColumnDefs(updatedColumnDef: ColDef[]) {
    this.grid.api.setGridOption('columnDefs', updatedColumnDef);
  }

  onSelectionChanged(event: any) {
    const selectedRows = this.grid.api.getSelectedRows();
    if (selectedRows.length) {
      const filteredArray = this.selectedRows.filter((element: any) => {
        return selectedRows.includes(element);
      });
      this.selectedRows = [...filteredArray, ...selectedRows];
    }
    this.selectedRows = Array.from(
      new Set(this.selectedRows.map((obj: any) => JSON.stringify(obj)))
    ).map((str: any) => JSON.parse(str));
    this.onRowSelection.emit(this.selectedRows);
  }
}
