import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Routes } from '../routes/api-routes';
import {
  InspectionFindingsEntity,
  InspectionFindingsList,
  LabelsMap,
  RelatedEntities,
} from '../../interfaces';
import { FindingsListFilters } from '../../interfaces/findings-dashboard.interface';
import { HelperService } from '../helpers/helper.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FindingsService {
  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService,
  ) {}

  getFindingsByAsset(findingFilters: FindingsListFilters) {
    return this.httpClient
      .post<InspectionFindingsList>(Routes.FINDINGS_LIST, {
        ...this.helperService.filterObject(findingFilters, 'filters'),
        withCounts: 'true',
      })
      .pipe();
  }

  getFindingsOnCursorBasePagination(findingFilters: FindingsListFilters) {
    return this.httpClient
      .post<InspectionFindingsList>(Routes.FINDINGS_LIST, {
        ...this.helperService.filterObject(findingFilters, 'filters'),
      })
      .pipe();
  }

  getFindingsTypesLabelsMap() {
    return this.httpClient.get<LabelsMap>(Routes.FINDING_TYPES_MAP).pipe();
  }

  async getAllFindings(filters?: FindingsListFilters): Promise<{
    allFindings: InspectionFindingsEntity[];
    relatedEntities: RelatedEntities;
  }> {
    const allFindings: InspectionFindingsEntity[] = [];
    const relatedEntitiesMap: RelatedEntities = {
      assetsMap: {},
      componentsMap: {},
      dataResourcesMap: {},
      missionsMap: {},
      sitesMap: {},
    };
    const recursiveRetrieve = async (nextCursor?: string): Promise<void> => {
      const allFindingsOfAsset: InspectionFindingsList = await lastValueFrom(
        this.getFindingsByAsset({
          ...filters,
          nextCursor,
          pageLimit: '500',
        }),
      );
      allFindings.push(...allFindingsOfAsset.findingDetails.records);
      Object.keys(relatedEntitiesMap).forEach((mapEntity) => {
        const key = mapEntity as keyof RelatedEntities;
        if (key != 'auditChecklist') {
          relatedEntitiesMap[key] = {
            ...relatedEntitiesMap[key],
            ...allFindingsOfAsset.relatedEntities[key],
          };
        }
      });

      if (allFindingsOfAsset.findingDetails.meta.nextCursor) {
        await recursiveRetrieve(
          allFindingsOfAsset.findingDetails.meta.nextCursor,
        );
      }
    };
    await recursiveRetrieve(filters?.nextCursor);
    return {
      allFindings: allFindings,
      relatedEntities: relatedEntitiesMap,
    };
  }
}
