<pdf-page
  class="mt-3"
  [pdfPageOptions]="{
    showFooter: false,
  }"
>
  <div
    class="d-flex flex-column justify-content-between align-items-center audit-page-wrapper"
  >
    <div>
      <span class="audit-page-title"> Audit Checklist </span>
    </div>
    <div class="questions-section">
      <div class="page-title">{{ auditChecklist.title }}</div>
      <table
        class="table table-striped questions-table"
        aria-describedby="questions-section"
      >
        <thead>
          <tr>
            <th scope="col" class="table-text">No</th>
            <th scope="col" class="table-text">Checklist</th>
            <th scope="col" class="table-text">Findings</th>
            <th scope="col" class="table-text">Remark</th>
            <th scope="col" class="table-text">Page No</th>
          </tr>
        </thead>
        <tbody>
          @for(questionEntity of auditChecklist.auditQuestions ;track
          questionEntity; let index = $index ) {
          @if(questionEntity.findingLabels.length) { @for(findingLabel of
          questionEntity.findingLabels; track findingLabel) {
          @if(getAssociatedFindings(findingLabel)?.length) { @for(finding of
          getAssociatedFindings(findingLabel); track finding) {

          <tr class="question-entity">
            <th scope="row" class="table-text">{{ index + 1 }}</th>
            <td class="table-text">{{ questionEntity.title }}</td>
            <td class="table-text">Yes</td>
            <td class="table-text">
              {{ finding?.description }}
            </td>
            <td
              class="table-text c-pointer"
              (click)="scrollToElement(findingLabel)"
            >
              {{ getFindingPageNo(finding._id) }}
            </td>
          </tr>

          } } } } }
        </tbody>
      </table>
    </div>
  </div>
</pdf-page>
