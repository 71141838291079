<div
  class="findings-chart-container"
  [ngClass]="{ 'dark-bg': findingsChartOptions.darkBg }">
  <div class="d-flex align-items-start g-10">
    <img
      [src]="'/images/warn-icon.svg' | assetUrl"
      alt="warn-icon"
      class="warn-icon"
      *ngIf="findingsChartOptions.showIcon" />
    <h3
      class="heading"
      [ngClass]="{ 'gray-color': findingsChartOptions.darkBg }">
      {{ findingsChartOptions.chartHeading }}
    </h3>
  </div>
  <span
    class="count"
    *ngIf="findingsCount > 0"
    [ngStyle]="{ color: findingsChartOptions.textColor }">
    {{ helperService.addCommasToNumber(findingsCount) }}
  </span>
  <span
    class="empty-placeholder"
    *ngIf="findingsCount === 0"
    [ngStyle]="{ color: findingsChartOptions.textColor }">
    No Data Available
  </span>
</div>
