import { Component, Input, SimpleChanges } from '@angular/core';
import { SEVERITY_LEVELS } from 'projects/dashboard-reporting/src/app/shared/constants/index.constants';
import {
  BarChartOptions,
  ChartData,
  FindingEntity,
  FindingsByPriority,
  FindingsChartOptions,
  MissionDetails,
  DonutChartOptions,
} from 'projects/dashboard-reporting/src/app/shared/interfaces';
import { HelperService } from 'projects/dashboard-reporting/src/app/shared/services/helpers/helper.service';
import { LabelsMapService } from 'projects/dashboard-reporting/src/app/shared/services/labels-map/labels-map.service';
import * as moment from 'moment';

@Component({
  selector: 'report-summary-page',
  templateUrl: './report-summary-page.component.html',
  styleUrls: ['./report-summary-page.component.scss'],
})
export class ReportSummaryPageComponent {
  _severityFindingsChartData: ChartData[] = [];
  _findingsByTypeChartData: ChartData[] = [];
  _findingsChartLabels: Array<string> = [];
  _totalFindingsChartData!: number;
  _criticalFindingsChartData!: number;
  priorityChartConfig!: DonutChartOptions;
  findingsTypeChartConfig!: BarChartOptions;
  totalFindingsChartOptions!: FindingsChartOptions;
  criticalFindingsChartOptions!: FindingsChartOptions;
  @Input() summaryChartsData!: FindingsByPriority[];
  @Input() missionDetails!: MissionDetails;
  @Input() assetsList!: any;

  constructor(
    public helperService: HelperService,
    private labelsMapService: LabelsMapService
  ) {}

  ngOnInit() {
    this.priorityChartConfig = {
      legendPosition: 'right',
      showLabelLine: false,
      showLabels: true,
      align: 'left',
      tooltipText: 'Findings',
      labelPosition: 'inner',
      textColor: '#29292A',
      labelFontSize: '16px',
    };
    this.findingsTypeChartConfig = {
      textColor: '#29292A',
    };
    this.totalFindingsChartOptions = {
      chartHeading: 'Total Findings',
      showIcon: false,
      textColor: '#29292A',
      bgColor: '',
      headingColor: '#29292A',
      darkBg: false,
    };
    this.criticalFindingsChartOptions = {
      chartHeading: 'Critical Findings',
      showIcon: true,
      textColor: '#29292A',
      bgColor: '',
      headingColor: '#29292A',
      darkBg: false,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['summaryChartsData']) {
      this.setSeverityChartData(this.summaryChartsData);
    }
  }

  async setSeverityChartData(chartData: FindingsByPriority[]) {
    const tempArr: ChartData[] = [];
    for (const item of chartData) {
      tempArr.push({
        name: await this.labelsMapService.getLabelName(item.severityType),
        value: item.totalCount,
      });
    }
    this._severityFindingsChartData =
      this.helperService.sortArrayInDescendingOrder(tempArr);
    this._findingsByTypeChartData =
      this.helperService.sortArrayInDescendingOrder(
        this.parseFindingsTypeChartData(chartData)
      );
    await this.setFindingsLabels(this._findingsByTypeChartData);
    this._totalFindingsChartData =
      this.helperService.getSumOfTotalCount(chartData);
    this._criticalFindingsChartData =
      chartData.find(
        async (item: FindingsByPriority) =>
          (
            await this.labelsMapService.getLabelName(item.severityType)
          ).toLowerCase() == SEVERITY_LEVELS.HIGH
      )?.totalCount ?? 0;
  }

  parseFindingsTypeChartData(chartData: FindingsByPriority[]) {
    const findingTypeCounts: ChartData[] = [];
    chartData
      .filter(
        async (item) =>
          (
            await this.labelsMapService.getLabelName(item.severityType)
          ).toLowerCase() == SEVERITY_LEVELS.HIGH
      )
      .forEach((item: any) => {
        item.findings.forEach((finding: FindingEntity) => {
          const { findingType, count } = finding;
          const itemIndex = findingTypeCounts.findIndex(
            (entity: ChartData) => entity.name == findingType
          );
          if (itemIndex != -1) {
            findingTypeCounts[itemIndex] = {
              ...findingTypeCounts[itemIndex],
              value: findingTypeCounts[itemIndex].value + count,
            };
          } else {
            findingTypeCounts.push({
              name: findingType,
              value: count,
            });
          }
        });
      });
    return findingTypeCounts;
  }

  getParsedDate(date?: string | undefined) {
    return date?.length
      ? moment(date).format('DD MMM YYYY')
      : moment().format('DD MMM YYYY');
  }

  async setFindingsLabels(dataSource: ChartData[]) {
    let labels: any = [];
    labels = dataSource.map(
      async (item) => await this.labelsMapService.getLabelName(item.name)
    );
    this._findingsChartLabels = [...(await Promise.all(labels))];
  }
}
