import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssetUrlPipe } from 'projects/dashboard-reporting/src/app/shared/pipes/asset-url.pipe';
import { NgxEchartsModule } from 'ngx-echarts';
import { IsGrantedDirective } from './directives/user-permissions/user-permission.directive';
import { FindingsChartComponent } from './components/chart-widget/findings-chart/findings-chart.component';
import { HalfDonutChartComponent } from './components/chart-widget/half-donut-chart/half-donut-chart.component';
import { AreaChartComponent } from './components/chart-widget/area-chart/area-chart.component';
import { ScatterChartComponent } from './components/chart-widget/scatter-chart/scatter-chart.component';
import { SingleBarChartComponent } from './components/chart-widget/single-bar-chart/single-bar-chart.component';
import { BarChartComponent } from './components/chart-widget/bar-chart/bar-chart.component';
import { SearchableChecboxGroupComponent } from './components/searchable-checkbox-group/searchable-checkbox-group.component';
import { PdfPageComponent } from './components/pdf-page/pdf-page.component';
import { VisualAnnotationPanelComponent } from './components/visual-annotation-panel/visual-annotation-panel.component';
import { AssetMapComponent } from './components/asset-map/asset-map.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { AgGridAngular } from 'ag-grid-angular';
import { PaginationComponent } from './components/pagination/pagination.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { PieChartComponent } from './components/chart-widget/pie-chart/pie-chart.component';
import { DonutChartComponent } from './components/chart-widget/donut-chart/donut-chart.component';
import { ModalComponent } from './components/modal/modal.component';

const Module = [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule,AgGridAngular];

const Pipes = [AssetUrlPipe];

const CHARTS = [
  PieChartComponent,
  FindingsChartComponent,
  DonutChartComponent,
  HalfDonutChartComponent,
  AreaChartComponent,
  ScatterChartComponent,
  SingleBarChartComponent,
  BarChartComponent,
  DataTableComponent
];

const SHARED_COMPONENTS = [
  SearchableChecboxGroupComponent,
  PdfPageComponent,
  VisualAnnotationPanelComponent,
  AssetMapComponent,
  PaginationComponent,
  MultiSelectComponent,
  ModalComponent,
];

const Directives = [IsGrantedDirective];

@NgModule({
  declarations: [...Pipes, ...CHARTS, ...Directives, ...SHARED_COMPONENTS],
  imports: [
    ...Module,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  exports: [
    ...Module,
    ...Pipes,
    ...CHARTS,
    ...Directives,
    ...SHARED_COMPONENTS,
  ],
})
export class SharedModule {}
