<div class="pagination-wrapper">
  <div class="d-flex justify-content-between align-items-center sub-heading">
    <span class="bold-text">Showing</span>
    <span class="gray-value">{{ (currentPage - 1) * 10 + 1 }}</span>
    <span class="bold-text">to</span>
    <span class="gray-value">{{
      getMinValue(currentPage * 10, totalRecords)
    }}</span>
    <span class="bold-text">of</span>
    <span class="gray-value">{{ totalRecords }}</span>
  </div>
  <div class="pagination-container">
    <button (click)="goToFirstPage()" class="icon-color"><<</button>
    <button (click)="decrementPage()" class="icon-color"><</button>
    <ng-container *ngFor="let page of visiblePages">
      @if (page !== "...") {
        <button (click)="goToPage(page)" [class.active]="currentPage === page">
          {{ page }}
        </button>
      } @else if (page === "...") {
        <span class="ellipse-icon">{{ page }}</span>
      }
    </ng-container>
    <button (click)="incrementPage()" class="icon-color">></button>
    <button (click)="goToLastPage()" class="icon-color">>></button>
  </div>
</div>
