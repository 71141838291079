<div *ngIf="isAtRoot()">
  <app-asset-owner-panel
    *PermissionGuard="[USER_PERMISSIONS.REPORT_DASHBOARD_ASSET]"
    [visiableComponents]="dashboardRightPanelComponents"
  ></app-asset-owner-panel>
  <app-mission-charts-panel
    *PermissionGuard="[USER_PERMISSIONS.REPORT_DASHBOARD_PILOT]"
  ></app-mission-charts-panel>
</div>
<router-outlet></router-outlet>
