import { Component, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ChartData, HalfDonutChartOptions } from 'projects/dashboard-reporting/src/app/shared/interfaces';
import { HelperService } from 'projects/dashboard-reporting/src/app/shared/services/helpers/helper.service';

@Component({
  selector: 'app-half-donut-chart',
  templateUrl: './half-donut-chart.component.html',
  styleUrls: ['./half-donut-chart.component.scss'],
})
export class HalfDonutChartComponent {
  echartsOptions: EChartsOption = {};
  @Input() dataSource: ChartData[] = [];
  @Input() chartTitle = '';
  @Input() chartOptions!: HalfDonutChartOptions;

  constructor(private helperService: HelperService) {}

  ngOnInit() {
    this.echartsOptions = {
      title: {
        text: this.chartTitle,
        left: 'center',
        top: 'center',
        textStyle: {
          color: '#FFF',
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: `${this.chartOptions.tooltipText} {c}`,
        padding: 5,
        backgroundColor: '#000',
        textStyle: {
          color: '#FFF',
        },
      },
      series: [
        {
          type: 'pie',
          avoidLabelOverlap: false,
          data: [...this.dataSource, this.getPlaceHolderSlice()],
          selectedMode: 'single',
          center: ['60%', '70%'],
          startAngle: 180,
          label: {
            show: this.chartOptions?.showLabels ?? true,
            // @ts-ignore
            position: this.chartOptions?.labelPosition ?? 'inner',
            formatter: function (params: any) {
              return `${params.percent}%`;
            },
            color: '#FFF',
            distanceToLabelLine: 0.1,
            fontSize: '11px',
          },
          labelLine: {
            show: this.chartOptions?.showLabelLine ?? true,
          },
        },
      ],
    };
    this.setLegendOptions();
  }
  onChartClick(event: any) {}

  setLegendOptions() {
    if (this.chartOptions?.legendPosition == 'left') {
      this.echartsOptions.legend = {
        ...this.echartsOptions.legend,
        orient: 'vertical',
        left: '10px',
        top: 'center',
        textStyle: {
          color: '#FFF',
        },
      };
      return;
    }
    this.echartsOptions.legend = {
      ...this.echartsOptions.legend,
      orient: 'horizontal',
      bottom: '10px',
      textStyle: {
        color: '#FFF',
      },
    };
  }

  getPlaceHolderSlice() {
    const totalCount: number = this.helperService.getTotalValues(
      this.dataSource
    );
    return {
      value: totalCount,
      itemStyle: {
        color: 'none',
        decal: {
          symbol: 'none',
        },
      },
      label: {
        show: false,
      },
    };
  }
}
