import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import Konva from 'konva';
import { Layer } from 'konva/lib/Layer';
import { Stage } from 'konva/lib/Stage';
import {
  ANNOTATION_DRAWBOX_INIT_PROPERTY,
  STAGE_2D_CANVAS_COMMON_VIEW,
} from '../../constants/index.constants';
import { AnnotationVisualCanvasService } from '../../services/annotation/annotation-visual-canvas.service';
import {
  ICanvasImageProperty,
  ICanvasStageProperties,
  IThumbnailInstance,
} from '../../interfaces';
import { HelperService } from '../../services/helpers/helper.service';
@Component({
  selector: 'app-visual-annotation-panel',
  templateUrl: './visual-annotation-panel.component.html',
  styleUrls: ['./visual-annotation-panel.component.scss'],
})
export class VisualAnnotationPanelComponent {
  stage!: Stage;
  layer!: Layer;
  drawBox: any;
  drawMode: string = ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWMODE;
  viewer_stage_name: string = STAGE_2D_CANVAS_COMMON_VIEW.THUMB_STAGENAME;
  polyTypeStatus: boolean = false;
  imageStatus!: 'loading' | 'loaded' | 'failed';

  @ViewChild('thumbCanvasContainer', { read: ElementRef })
  thumbCanvasContainerRef!: ElementRef<HTMLCanvasElement>;

  @Input() backgroundImageSrc!: string | undefined;
  @Input() dataCoordinates!: Array<any>;
  @Input() stageProperty!: ICanvasStageProperties | undefined;

  constructor(
    private annotationVisualCanvasService: AnnotationVisualCanvasService,
    private helperService: HelperService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.imageStatus = 'loading';
  }

  //** Dynamic Thumbnail image viewer list canvas- */
  ngAfterViewInit(): void {
    if (this.stageProperty?.id) {
      const containerElement =
        this.thumbCanvasContainerRef.nativeElement.querySelector(
          '#' + this.stageProperty.id
        );
      if (containerElement) {
        // Loaded once afterViewInit :on thumbnail mode
        this.InitializeCanvasDynamicInstance();
      }
    }
  }

  InitializeCanvasDynamicInstance() {
    if (this.stageProperty) {
      const containerElement =
        this.thumbCanvasContainerRef.nativeElement.querySelector(
          '#' + this.stageProperty.id
        );
      if (containerElement) {
        let stage = new Konva.Stage({
          container: containerElement.id,
          width: this.stageProperty.width,
          height: this.stageProperty.height,
        });
        stage.setAttr('id', this.stageProperty.id);
        stage.setAttr('name', this.viewer_stage_name);
        let layer = new Konva.Layer();
        let getThumbInstance: IThumbnailInstance = {
          stage: stage,
          layer: layer,
        };
        this.setBgImage(getThumbInstance);
      }
    }
  }

  setBgImage(canvasInstance: IThumbnailInstance) {
    let stage = canvasInstance.stage;
    let layer = canvasInstance.layer;
    let imageObj = new Image();
    if (this.backgroundImageSrc) {
      imageObj.src = this.backgroundImageSrc;
      imageObj.crossOrigin = 'Anonymous';
      imageObj.onload = () => {
        this.imageStatus = 'loaded';
        this.cdr.detectChanges();
        let aspectRatio = imageObj.width / imageObj.height;
        let stageHeight = stage.width() / aspectRatio;
        stage.setHeight(stageHeight);
        let imgProperties: ICanvasImageProperty = {
          image: imageObj,
          width: stage.width(),
          height: stageHeight,
          opacity: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_OPACITY_INIT,
        };
        let bgImage = new Konva.Image(imgProperties);
        let bgImgGroupInstance = new Konva.Group({
          draggable: false,
        });
        if (bgImgGroupInstance) {
          bgImgGroupInstance.add(bgImage);
          layer.add(bgImgGroupInstance);
          stage.add(layer);
          stage.draw();
          canvasInstance['bgGroup'] = bgImgGroupInstance;
          this.getAllDrawBoxes(
            canvasInstance,
            this.dataCoordinates,
            imgProperties
          );
        }
      };
      imageObj.onerror = () => {
        this.imageStatus = 'failed';
        this.cdr.detectChanges();
      };
    } else {
      this.imageStatus = 'failed';
      this.cdr.detectChanges();
    }
  }

  getAllDrawBoxes(
    canvasInstance: IThumbnailInstance,
    dataCoordinates: Array<any>,
    imgProperties: ICanvasImageProperty
  ) {
    if (dataCoordinates && dataCoordinates.length > 0) {
      for (let data of dataCoordinates) {
        let pointsCoordinate = data.geometry.coordinates;
        this.polyTypeStatus = true;
        if (pointsCoordinate && pointsCoordinate.length > 0)
          this.getDrawPolygonBox(data, canvasInstance, imgProperties);
      }
    }
  }

  getDrawPolygonBox(
    data: any,
    canvasInstance: IThumbnailInstance,
    imgProperties: ICanvasImageProperty
  ) {
    let stage = canvasInstance.stage;
    let layer = canvasInstance.layer;
    let group = canvasInstance.bgGroup;
    let pointsCoordinate: [number, number][] = data.geometry.coordinates;
    let calculatedPoints: [number, number][] =
      this.annotationVisualCanvasService.getPolygonCoordinatesBasedOnImageAxis(
        imgProperties,
        pointsCoordinate
      );
    let findingId = data.findingId || '';
    const singleArPolyPoints: number[] =
      this.helperService.getSingleFlatArray(calculatedPoints);
    let polygonProperties: any = {
      points: singleArPolyPoints,
      fill: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_FILL,
      stroke: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE,
      strokeWidth:
        ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_POLY_STROKEWIDTH_THUMB,
      closed: this.polyTypeStatus,
      hitStrokeWidth:
        ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_POLY_STROKEWIDTH_THUMB,
      name: ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME,
      id: data['_id'],
      draggable: false,
    };
    const polygon = new Konva.Line(polygonProperties);
    polygon.setAttr('newRefPoint', singleArPolyPoints);
    polygon.setAttr('findingId', findingId);
    polygon.setAttr('componentName', '');
    let shapeGroup = new Konva.Group({
      draggable: false,
      name: 'shapeGroup',
      id: polygon.id(),
    });
    shapeGroup.add(polygon);
    group.add(shapeGroup);
    layer.add(group);
    stage.add(layer);
    this.polyTypeStatus = false;
  }
}
