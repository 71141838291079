<div class="report-filter-panel-wrapper">
  <div class="d-flex justify-content-between">
    <span class="heading">Generate report</span>
    <img [src]="'/images/cross-icon.svg' | assetUrl" alt="cross-icon" />
  </div>
  <div class="tabs-wrapper">
    <span
      [ngClass]="{
        'active-tab': activeTab == 'cover',
        'inactive-tab': activeTab == 'content'
      }"
      (click)="activeTab = 'cover'"
      >Cover</span
    >
    <span
      [ngClass]="{
        'active-tab': activeTab == 'content',
        'inactive-tab': activeTab == 'cover'
      }"
      (click)="activeTab = 'content'"
      >Content</span
    >
  </div>

  @if(activeTab == 'cover'){
  <span class="c-details-heading my-5">Cover Details</span>
  <button class="search-btn my-3" (click)="showLibraryModal = true">
    Search From Library
  </button>
  @if(!coverPageData?.cover?.url) {
  <div class="upload-file-holder dropzone" fileDragDrop>
    <input
      type="file"
      #fileDropRef1
      id="fileDropRef1"
      (change)="onFileUpload(fileDropRef1.files, 'report-cover-image')"
    />
    <a href="javascript:void(0);" class="upload-icon"
      >Upload/Import Cover Image</a
    >
  </div>
  } @if(!coverPageData?.logo?.url) {
  <div class="upload-file-holder dropzone mt-3" fileDragDrop>
    <input
      type="file"
      #fileDropRef11
      id="fileDropRef11"
      (change)="onFileUpload(fileDropRef11.files, 'report-logo')"
      [disabled]="coverPageData?.recipient?.length"
    />
    <a href="javascript:void(0);" class="upload-icon"
      >Upload/Import Recipient Logo Image
    </a>
  </div>
  }

  <div class="d-flex flex-column my-3">
    @if(coverPageData?.cover?.url) {
    <div class="selected-image-container my-3">
      <span class="image-name">
        {{ helperService.trimString(coverPageData?.cover?.name ?? "", 15) }}
      </span>
      <div class="icon-container">
        <img
          [src]="'/images/edit-icon.svg' | assetUrl"
          alt="edit-icon"
          (click)="editImage()"
        />
        <img
          [src]="'/images/cross-icon.svg' | assetUrl"
          alt="cross-icon"
          (click)="removeImage('cover')"
        />
      </div>
    </div>
    } @if(coverPageData?.logo?.url) {
    <div class="selected-image-container my-3">
      <span class="image-name">
        {{ helperService.trimString(coverPageData?.logo?.name ?? "", 15) }}
      </span>
      <div class="icon-container">
        <img
          [src]="'/images/edit-icon.svg' | assetUrl"
          alt="edit-icon"
          (click)="editImage()"
        />
        <img
          [src]="'/images/cross-icon.svg' | assetUrl"
          alt="cross-icon"
          (click)="removeImage('logo')"
        />
      </div>
    </div>
    }
  </div>
  <span class="optional-text">OR</span>
  <input
    type="text"
    (change)="onRecipientNameChange($event)"
    class="recipient-name"
    placeholder="Enter the Recipient Name"
    [disabled]="coverPageData?.logo?.url?.length"
  />

  } @else if(activeTab === 'content'){
  <div class="d-flex flex-column g-22 mt-5">
    @for(filterKey of reportFilterKeys;track filterKey) {
    <span class="selected-heading"
      >Selected {{ filterKey.toUpperCase() }} ({{
        getFilters(filterKey)?.length
      }})</span
    >
    <div class="d-flex flex-column g-12">
      @for(filters of getFilters(filterKey);track filters) {
      <span class="selected-value">{{
        filters?.assetName ?? filters?.name
      }}</span>
      }
    </div>
    }
  </div>
  }
</div>
@if(showLibraryModal){
<image-gallery
  [isVisible]="showLibraryModal"
  (onModalClose)="closeModal()"
  (onImagesSelect)="setSelectedImages($event)"
></image-gallery>
}
