import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ReportCoverPayload } from 'projects/dashboard-reporting/src/app/shared/interfaces';
import { state } from '@app/utility';

@Component({
  selector: 'report-cover-page',
  templateUrl: './report-cover-page.component.html',
  styleUrls: ['./report-cover-page.component.scss'],
})
export class ReportCoverPageComponent {
  @Input() coverPageData: ReportCoverPayload;
  @Output() onReportTitleChange: EventEmitter<ReportCoverPayload> =
    new EventEmitter();
  workspaceName = '';
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    const workspaceInfo = state.getActiveWorkspaceEntity();
    this.workspaceName = workspaceInfo.workspace.name;
  }

  ngOnChanges() {
    this.cdr.detectChanges();
  }

  onTitleChange(event: any) {
    this.onReportTitleChange.emit({
      title: event.target.value,
    });
  }
}
