import { Component, EventEmitter, Input, Output } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import {
  FindingsReportFilters,
  ReportCoverPayload,
} from 'projects/dashboard-reporting/src/app/shared/interfaces';
import { HelperService } from 'projects/dashboard-reporting/src/app/shared/services/helpers/helper.service';
import { IMAGE_RESOURCE_TYPES } from 'projects/dashboard-reporting/src/app/shared/constants/index.constants';
import { DataResourceService } from 'projects/dashboard-reporting/src/app/shared/services/data-resource/data-resource.service';
import { StoreService } from 'projects/dashboard-reporting/src/app/shared/services/store/store.service';

@Component({
  selector: 'report-filter-panel',
  templateUrl: './report-filter-panel.component.html',
  styleUrls: ['./report-filter-panel.component.scss'],
})
export class ReportFilterPanelComponent {
  @Output() onCoverDataChange: EventEmitter<ReportCoverPayload> =
    new EventEmitter();

  reportFilters: FindingsReportFilters = {
    assets: [],
    components: [],
    inspectionFindings: [],
    severityLabels: [],
    missions: [],
  };
  reportFilterKeys: any = [];
  activeTab: 'cover' | 'content' = 'cover';
  showLibraryModal = false;
  coverPageData: ReportCoverPayload;
  constructor(
    public helperService: HelperService,
    private dataResource: DataResourceService,
    private storeService: StoreService,
  ) {}

  ngOnInit() {
    this.reportFilters = this.storeService.getReportFilters();
    this.reportFilterKeys = this.helperService.getObjectKeys(
      this.reportFilters,
    );
  }

  closeModal() {
    this.showLibraryModal = false;
  }

  setSelectedImages(selectedImages: any) {
    this.coverPageData = {
      ...this.coverPageData,
      cover: selectedImages['report-cover-image'].url?.length
        ? selectedImages['report-cover-image']
        : this.coverPageData?.cover,
      logo: selectedImages['report-logo'].url?.length
        ? selectedImages['report-logo']
        : this.coverPageData?.logo,
    };
    this.onCoverDataChange.emit({
      ...this.coverPageData,
    });
  }

  getObjectKeys(obj: object) {
    return obj ? Object.keys(obj) : [];
  }

  removeImage(imageType: string) {
    delete this.coverPageData[imageType as keyof ReportCoverPayload];
    this.onCoverDataChange.emit({
      ...this.coverPageData,
    });
  }

  editImage() {
    this.showLibraryModal = true;
  }

  onRecipientNameChange(event: any) {
    this.coverPageData = {
      ...this.coverPageData,
      recipient: event.target.value,
    };
    this.onCoverDataChange.emit({
      ...this.coverPageData,
    });
  }

  async onFileUpload(file: any, type: 'report-logo' | 'report-cover-image') {
    try {
      if (type == IMAGE_RESOURCE_TYPES.REPORT_COVER_IMAGE)
        this.coverPageData = {
          ...this.coverPageData,
          cover: {
            url: URL.createObjectURL(file[0]),
            name: file[0].name,
            dataResourceId: await this.uploadFile(file[0], type),
          },
        };
      else if (type == IMAGE_RESOURCE_TYPES.REPORT_LOGO) {
        this.coverPageData = {
          ...this.coverPageData,
          logo: {
            url: URL.createObjectURL(file[0]),
            name: file[0].name,
            dataResourceId: await this.uploadFile(file[0], type),
          },
        };
      }
      this.onCoverDataChange.emit({
        ...this.coverPageData,
      });
    } catch (err) {}
  }

  async uploadFile(file: File, type: string): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);
    const dataResourceId = await lastValueFrom(
      this.dataResource.createDataResource(formData, type),
    );
    return dataResourceId[0];
  }

  getFilters(filterKey: keyof FindingsReportFilters) {
    return this.reportFilters[filterKey];
  }
}
