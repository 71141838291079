import { Component, Input } from '@angular/core';
import { PdfPageOptions } from '../../interfaces';
import * as moment from 'moment';

@Component({
  selector: 'pdf-page',
  templateUrl: './pdf-page.component.html',
  styleUrls: ['./pdf-page.component.scss'],
})
export class PdfPageComponent {
  @Input() pdfPageOptions: PdfPageOptions = {
    pageNumber: 1,
    title: 'Summary',
    totalPages: 2,
    pageWidth: '793px',
    pageHeight: '1130px',
    showFooter: true,
    resetInternalStyles: false,
  };

  getCurrentDate() {
    return moment().format('MMMM D, YYYY');
  }
}
