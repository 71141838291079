import { NgModule } from '@angular/core';
import { SharedModule } from 'projects/dashboard-reporting/src/app/shared/shared.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ReportRoutingModule } from './report-routing.module';
import { GenerateMissionReportComponent } from './components/generate-mission-report-panel/generate-mission-report.component';
import { ReportFilterPanelComponent } from './components/report-filter-panel/report-filter-panel.component';
import { ReportSummaryPageComponent } from './components/report-summary-page/report-summary-page.component';
import { FindingsDetailsPageComponent } from './components/findings-details-page/findings-details-page.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { ReportAuditChecklistPageComponent } from './components/audit-checklist/audit-checklist.component';
import { ReportCoverPageComponent } from './components/report-cover-page/report-cover-page.component';

@NgModule({
  imports: [ReportRoutingModule, SharedModule, ButtonsModule.forRoot()],
  declarations: [
    GenerateMissionReportComponent,
    ReportFilterPanelComponent,
    ReportSummaryPageComponent,
    FindingsDetailsPageComponent,
    ImageGalleryComponent,
    ReportCoverPageComponent,
    ReportAuditChecklistPageComponent,
  ],
  exports: [],
})
export class ReportModule {}
