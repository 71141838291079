import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionManagerService } from './user-permission.service'

@Directive({
  selector: '[PermissionGuard]'
})

export class IsGrantedDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionManagerService: PermissionManagerService,
  ) {}  
  
  @Input() 
  set PermissionGuard(permission: string[]) {
    this.isGranted(permission);
  }  

  private isGranted(permission: string[]) {
    if (this.permissionManagerService.isGranted(permission)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}