import { Component, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ChartData } from 'projects/dashboard-reporting/src/app/shared/interfaces';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss'],
})
export class AreaChartComponent {
  echartsOptions: EChartsOption = {};
  @Input() dataSource: ChartData[] = [];
  ngOnInit() {
    this.echartsOptions = {
      grid: {
        show: false,
        top:25
      },
      tooltip: {
        trigger: 'axis',
        formatter: `{b}: {c}`,
        padding: 5,
        backgroundColor: '#000',
        textStyle: {
          color: '#FFF',
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: this.getChartLabel(),
        splitLine: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: '#C8C8C8',
          rotate:45
        },
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false,
        },
        axisLabel: {
          color: '#C8C8C8',
        },
      },
      series: [
        {
          data: this.getLineChartData(),
          type: 'line',
          showSymbol: false,
          areaStyle: {
            color: 'rgba(92, 217, 239, 0.05)',
          },
          lineStyle: {
            color: '#00F0FF',
          },
          smooth: true,
        },
      ],
    };
  }
  onChartClick(event: any) {}

  getLineChartData() {
    const tempArr: number[] = [];
    for (const item of this.dataSource) {
      tempArr.push(item.value);
    }
    return tempArr;
  }

  getChartLabel() {
    const labels: string[] = [];
    for (const item of this.dataSource) {
      labels.push(item.name);
    }
    return labels;
  }
}
