<div class="multi-select-container" #myElement>
  <div class="selected-options-wrapper position-relative">
    @if (getSelectedFiltersCount() > 0) {
      <span class="selected-filters-count">
        {{ getSelectedFiltersCount() > 9 ? "9+" : getSelectedFiltersCount() }}
      </span>
    }
    <input
      type="search"
      class="search-field"
      (input)="onSearchItem($event)"
      placeholder="Search"
    />
    <img
      [src]="'/images/gray-arrow-down.svg' | assetUrl"
      alt="down-arrow"
      class="dropdown-icon"
      (click)="toggleDropdown()"
    />
  </div>
  <div
    class="custom-dropdown"
    [ngClass]="{ 'd-none': !isDropdownOpen }"
    [style.top]="dimensions.top"
    [style.left]="dimensions.left"
  >
    <div
      class="options"
      #dropdownElement
      [ngClass]="{ 'd-none': !options?.length }"
    >
      @if (filteredOptions()?.length) {
        <label class="d-flex align-items-center custom-checkbox">
          <input
            type="checkbox"
            [(ngModel)]="selectAll"
            (ngModelChange)="onAllSelect()"
          />
          <div class="checkmark"></div>
          Select All
        </label>
      }
      <label
        *ngFor="let option of filteredOptions()"
        class="d-flex align-items-center custom-checkbox"
      >
        <input
          type="checkbox"
          [(ngModel)]="option.checked"
          (ngModelChange)="onOptionChange(option)"
        />
        <div class="checkmark"></div>
        {{ option.label }}
      </label>
    </div>
    <span
      class="empty-placeholder"
      [ngClass]="{ 'd-none': filteredOptions()?.length }"
      >No Record Found</span
    >
  </div>
</div>
@if (isDropdownOpen) {
  <div class="dropdown-backdrop" (click)="isDropdownOpen = false"></div>
}
