import { Component, Input } from '@angular/core';
import { FindingsChartOptions } from 'projects/dashboard-reporting/src/app/shared/interfaces';
import { HelperService } from 'projects/dashboard-reporting/src/app/shared/services/helpers/helper.service';

@Component({
  selector: 'app-findings-chart',
  templateUrl: './findings-chart.component.html',
  styleUrls: ['./findings-chart.component.scss'],
})
export class FindingsChartComponent {
  @Input() findingsChartOptions: FindingsChartOptions;
  @Input() findingsCount = 0;

  constructor(public helperService: HelperService) {
    this.findingsChartOptions = {
      chartHeading: '',
      bgColor: 'rgba(158, 158, 158, 0.1)',
      showIcon: false,
      textColor: '#FFF',
      headingColor: '#c0cdd4',
      darkBg: true
    };
  }
}
