<div class="report-summary-page-wrapper" id="summary-page">
  <!-- Summary Page 01 -->
  <pdf-page
    class="mt-3"
    [pdfPageOptions]="{
      title: 'Summary',
      pageNumber: 1,
      totalPages: 2
    }"
    class="summary-page"
  >
    <div class="d-flex justify-content-between align-items-center">
      <span class="mission-name"> Inspection Findings Report </span>
      <span class="mission-name">Summary</span>
    </div>
    <div class="report-meta d-flex justify-content-between align-items-center">
      <span class="meta-text"> Inspection Findings Report </span>
      <span class="meta-text">
        {{ getParsedDate() }} -
        {{ getParsedDate() }}
      </span>
    </div>
    <div class="asset-map-container">
      <app-asset-map
        [assetsList]="assetsList"
        style="width: inherit; height: inherit"
      ></app-asset-map>
    </div>
    <div
      class="findings-stats-container d-flex justify-content-between align-items-center mt-3"
    >
      <div class="stats-container w-50">
        <app-findings-chart
          [findingsChartOptions]="totalFindingsChartOptions"
          [findingsCount]="_totalFindingsChartData"
          :key="selectedMissionId"
        ></app-findings-chart>
      </div>
      <div class="stats-container w-50">
        <app-findings-chart
          [findingsChartOptions]="criticalFindingsChartOptions"
          [findingsCount]="_criticalFindingsChartData"
        ></app-findings-chart>
      </div>
    </div>
    <div class="severity-chart-wrapper mt-3">
      <div class="severity-heading">Findings</div>
      <span class="severity-sub-heading">By Severity</span>
      <donut-chart
        [dataSource]="_severityFindingsChartData"
        [chartTitle]="
          helperService.getTotalValues(_severityFindingsChartData).toString()
        "
        [chartOptions]="priorityChartConfig"
        *ngIf="_severityFindingsChartData.length"
      ></donut-chart>
      <span *ngIf="!_severityFindingsChartData.length" class="empty-placeholder"
        >No Data Available</span
      >
    </div>
  </pdf-page>
  <!-- Summary Page 02 -->
  <pdf-page
    [pdfPageOptions]="{
      title: 'Summary',
      pageNumber: 2,
      totalPages: 2
    }"
    class="summary-page"
  >
    <div class="findings-by-type-chart mt-3">
      <div class="findings-heading">Findings By Type</div>
      <app-bar-chart
        [dataSource]="_findingsByTypeChartData"
        [chartOptions]="findingsTypeChartConfig"
        [findingsChartLabels]="_findingsChartLabels"
        [chartTitle]="
          this.helperService
            .getTotalValues(_findingsByTypeChartData)
            .toString() +
          ' ' +
          'Findings'
        "
        *ngIf="_findingsByTypeChartData.length"
        style="height: auto"
      >
      </app-bar-chart>
      <span *ngIf="!_findingsByTypeChartData.length" class="empty-placeholder"
        >No Data Available</span
      >
    </div>
  </pdf-page>
</div>
