<div class="searchable-checkbox-group d-flex flex-column align-items-start">
  <div class="selected-heading">
    {{ heading }} ( {{ getSelectedItems().length }} Selected )
  </div>
  <div class="search-item-box">
    <input
      type="search"
      class="search-item"
      [placeholder]="'Search' + ' ' + heading"
      (input)="onSearch($event)" />
    <img
      [src]="'/images/search-icon.svg' | assetUrl"
      alt="search-icon"
      class="search-icon" />
  </div>
  <span *ngIf="searchedQuery.length" class="result-text"
    >{{ getFilteredOptions().length }} Results Found</span
  >
  <div class="d-flex flex-column align-items-start items-wrapper">
    <label class="d-flex align-items-center custom-checkbox">
      <input
        type="checkbox"
        [(ngModel)]="selectAll"
        (ngModelChange)="onAllSelect()" />
      <div class="checkmark"></div>
      All ({{ options.length }})
    </label>
    <label
      *ngFor="let option of getFilteredOptions()"
      class="d-flex align-items-center custom-checkbox"
      [class.disabled-input]="!option.hasFindings"
      >
      <input
        type="checkbox"
        [(ngModel)]="option.checked"
        (ngModelChange)="onCheckboxChange()"
        [disabled]="!option.hasFindings"
        />
      <div class="checkmark"></div>
      {{ option.name }}
    </label>
  </div>
</div>
