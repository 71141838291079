import { Component, ElementRef, ChangeDetectorRef, Input } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'projects/dashboard-reporting/src/environments/environment';

@Component({
  selector: 'app-asset-map',
  templateUrl: './asset-map.component.html',
  styleUrls: ['./asset-map.component.scss'],
})
export class AssetMapComponent {
  @Input() assetsList!: any;

  mapBoxGl!: mapboxgl.Map;

  mapPadding = {
    zoomContracted: { top: 0, bottom: 0, left: 0, right: 0 },
    zoomExpanded: { top: 0, bottom: 0, left: 0, right: 0 },
  };

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initializeMap();
  }

  ngOnChanges() {
    if (this.assetsList && Object.keys(this.assetsList)?.length) {
      this.cdr.detectChanges();
      this.mapAssetsToMarkers();
      this.zoomToPoint([...this.getFindingsCenter()]);
    }
  }

  getFindingsCenter(): [number, number] {
    const assetCoordinates: any = [];
    Object.keys(this.assetsList).forEach((asset: any) => {
      assetCoordinates.push({
        assetLongitude: this.assetsList[asset]?.assetLongitude,
        assetLatitude: this.assetsList[asset]?.assetLatitude,
      });
    });
    let center: {
      lng: number;
      lat: number;
    };
    if (assetCoordinates.length === 1) {
      center = {
        lng: assetCoordinates[0].assetLongitude,
        lat: assetCoordinates[0].assetLatitude,
      };
    } else {
      const bounds = new mapboxgl.LngLatBounds();
      assetCoordinates.forEach((coord: any) =>
        bounds.extend({ lng: coord.assetLongitude, lat: coord.assetLatitude })
      );
      const { lng, lat } = bounds.getCenter();
      center = {
        lng,
        lat,
      };
    }
    return [center.lng, center.lat];
  }

  private initializeMap() {
    const map = new mapboxgl.Map({
      container: 'map-container',
      center: [10, 10],
      zoom: 12,
      accessToken: environment.mapbox.accessToken,
      style: 'mapbox://styles/intuitivebadger/clnbqvtnk03la01pjfg65g3r3',
      minZoom: 2,
      interactive: false,
      preserveDrawingBuffer: true,
    });
    map.setProjection('equirectangular');
    this.mapBoxGl = map;
    this.mapBoxGl.on('load', () => {});
  }

  mapAssetsToMarkers() {
    const assetMarkers =
      this.elementRef.nativeElement.querySelectorAll('.asset-marker');
    assetMarkers?.forEach((assetMarker: any) => {
      if (this.assetsList[assetMarker.id]) {
        const { assetLongitude, assetLatitude } =
          this.assetsList[assetMarker.id];
        new mapboxgl.Marker(assetMarker)
          .setLngLat([assetLongitude, assetLatitude])
          .addTo(this.mapBoxGl);
      }
    });
  }

  zoomToPoint(center: [number, number]) {
    this.mapBoxGl.flyTo({
      center,
      zoom: 10,
      speed: 1,
    });
  }

  getAllKeys(object: any) {
    return object ? Object.keys(object) : [];
  }
}
