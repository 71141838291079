import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_ROUTES_PREFIX } from './shared/constants/index.constants';
import { EmptyRouteComponent } from './shared/empty-route/empty-route.component';
const routes: Routes = [
  {
    path: `${APP_ROUTES_PREFIX}`,
    loadChildren: () =>
      import('./modules/report/report.module').then(
        module => module.ReportModule
      ),
  },
  {
    path: `${APP_ROUTES_PREFIX}/findings`,
    loadChildren: () =>
      import('./modules/asset-findings-dashboard/asset-findings.module').then(
        module => module.AssetFindingsModule
      ),
  },
  {
    path: '**',
    component: EmptyRouteComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
