<app-modal
  modalTitle="Media Library"
  [isVisible]="isVisible"
  class="gallery-modal"
  (closeModal)="closeModal()"
>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button
            class="accordion-button collapsed item-heading"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            Cover Images
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <div class="images-wrapper">
              @for(imageEntity of mediaGallery?.['report-cover-image'];track
              imageEntity.url) {
              <div
                class="d-flex flex-column resource-section"
                (click)="selectImage(imageEntity, 'report-cover-image')"
              >
                <img
                  [src]="imageEntity.url"
                  alt="cover"
                  class="resource-img"
                  [ngClass]="{
                    'selected-image':
                      selectedImages['report-cover-image'].name ===
                      imageEntity.name
                  }"
                />
                <span class="img-name">
                  {{ helperService.trimString(imageEntity.name, 32) }}
                </span>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingTwo">
          <button
            class="accordion-button collapsed item-heading"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo"
            aria-expanded="false"
            aria-controls="flush-collapseTwo"
          >
            Logo Images
          </button>
        </h2>
        <div
          id="flush-collapseTwo"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingTwo"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <div class="images-wrapper">
              @for(imageEntity of mediaGallery?.['report-logo'];track
              imageEntity.url) {
              <div
                class="d-flex flex-column resource-section"
                (click)="selectImage(imageEntity, 'report-logo')"
              >
                <img
                  [src]="imageEntity.url"
                  alt="cover"
                  class="resource-img"
                  [ngClass]="{
                    'selected-image':
                      selectedImages['report-logo'].name === imageEntity.name
                  }"
                />
                <span class="img-name">
                  {{ helperService.trimString(imageEntity.name, 32) }}
                </span>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="image-modal-footer">
      <button class="cancel-btn">CANCEL</button>
      <button class="done-btn" (click)="onImageSelection()">DONE</button>
    </div>
  </div>
</app-modal>
