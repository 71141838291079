import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChartData } from '../../interfaces';
import { Routes } from '../routes/api-routes';
import { Observable } from 'rxjs';
import { DataResourceList } from '../../interfaces/data-resource.interface';

@Injectable({
  providedIn: 'root',
})
export class DataResourceService {
  constructor(private httpClient: HttpClient) {}

  getDataResourceById(dataResourceId: string, getPreSignedUrl: boolean) {
    return this.httpClient
      .get<ChartData[]>(Routes.GET_DATA_RESOURCE + `/${dataResourceId}`, {
        params: {
          pre_signed_url: getPreSignedUrl,
        },
      })
      .pipe();
  }

  createDataResource(payload: any, resourceType: string): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${Routes.UPLOAD_RESOURCE}?resourceType=${resourceType}`,
      payload
    );
  }

  getAllDataResources(resourceType: string, getPreSignedUrl: boolean) {
    return this.httpClient
      .get<DataResourceList>(Routes.GET_DATA_RESOURCE, {
        params: {
          pre_signed_url: getPreSignedUrl,
          resourceType,
        },
      })
      .pipe();
  }
}
