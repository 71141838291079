import { Injectable } from '@angular/core';
import { ChartData } from '../../interfaces';
import { FindingsByPriority } from '../../interfaces/chart-api.interface';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  getTotalValues(data: Array<ChartData>) {
    let sum = 0;
    for (const item of data) {
      sum += item.value;
    }
    return sum;
  }

  addCommasToNumber(number: number) {
    let numberString = number.toString();
    numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return numberString;
  }

  getRandomColor = () => {
    return Math.floor(Math.random() * 16777215).toString(16);
  };

  getSumOfTotalCount(data: FindingsByPriority[]) {
    let sum = 0;
    for (const item of data) {
      sum += item.totalCount;
    }
    return sum;
  }

  sortArrayInDescendingOrder(arr: ChartData[]) {
    return arr.sort((a: ChartData, b: ChartData) => b.value - a.value);
  }

  getSingleFlatArray(points: any) {
    return points
      .reduce((acc: string | any[], val: string) => acc.concat(val), [])
      .flat();
  }

  decimalToDMS(decimal: number) {
    if (!decimal) return;
    const degrees = Math.floor(decimal);
    const minutesDecimal = (decimal - degrees) * 60;
    const minutes = Math.floor(minutesDecimal);
    const seconds = Math.floor(minutesDecimal - minutes) * 60;
    return `${degrees}° ${minutes}' ${seconds.toFixed(2)}"`;
  }

  filterObject(payload: any, excludedKey?: string) {
    const tempObj: any = {};
    if (payload) {
      for (let element of Object.keys(payload)) {
        if (element != excludedKey) {
          if (payload[element]?.length) tempObj[element] = payload[element];
        } else {
          tempObj[element] = payload[element];
        }
      }
    }
    return tempObj;
  }

  trimString(str: string, maxLength: number) {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength - 3) + '...';
    } else {
      return str;
    }
  }

  covertObjectToArray(obj: object) {
    return obj ? Object.values(obj) : [];
  }

  getObjectKeys(obj: object): Array<string> {
    return obj ? Object.keys(obj) : [];
  }

  mergeArrays(arr1: Array<any>, arr2: Array<any>) {
    const map = new Map();
    for (const obj of arr1) {
      map.set(obj._id, obj);
    }
    for (const obj of arr2) {
      const id = obj._id;
      if (map.has(id)) {
        Object.assign(map.get(id), obj);
      } else {
        map.set(id, obj);
      }
    }
    return Array.from(map.values());
  }

  parseDate(date: string, showTime?: boolean) {
    if (date) {
      return (
        moment(date).format('MMM DD, YYYY') +
        ' ' +
        `${showTime ? moment(date).format('hh:mmA') : ''}`
      );
    }
    return '';
  }
}
