import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChartData } from '../../interfaces';
import { Routes } from '../routes/api-routes';

@Injectable({
  providedIn: 'root'
})

export class DroneUsageChartService {
  
  constructor(private httpClient: HttpClient) { }

  getDroneUsageChartData() {
    return this.httpClient.get<ChartData[]>(Routes.TOTAL_MISSIONS_BY_DRONE,{}).pipe();
  }
}
