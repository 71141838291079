import { NgModule } from '@angular/core';
import { httpCustomInterceptor } from 'fe-micro-share-components';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { DashboardPanelsModule } from './modules/dashboard-panels/dashboard-panels.module';
import { EnvironmentService } from './shared/services/envoirnments/environment.service';
import { BrowserModule } from '@angular/platform-browser';
import { ReportModule } from './modules/report/report.module';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    DashboardPanelsModule,
    BrowserModule,
    SharedModule,
    ReportModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: httpCustomInterceptor,
      multi: true,
    },
    EnvironmentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
