// Severity Levels
export const SEVERITY_LEVELS = {
  HIGH: 'high',
};
//Routes Prefix
export const APP_ROUTES_PREFIX = 'dashboard';

export const ANNOTATION_DRAWBOX_INIT_PROPERTY = {
  DRAWMODE: 'rectangle',
  DRAWBOX_NAME: 'rect',
  POLY_NAME: 'poly',
  POLY_NAME_TEMP: 'polyTemp',
  DRAWBOX_X: 100,
  DRAWBOX_Y: 40,
  DRAWBOX_WIDTH: 425,
  DRAWBOX_HEIGHT: 450,
  DRAWBOX_FILL: 'rgba(0, 240, 255, 0.08)',
  DRAWBOX_STROKE: '#00F0FF',
  DRAWBOX_STROKEWIDTH: 1,
  DRAWBOX_STROKEWIDTH_T2: 0.8,
  DRAWBOX_STROKEWIDTH_CLK: 10,
  DRAWBOX_STROKEWIDTH_CLK_7: 7,
  DRAWBOX_STROKEWIDTH_CLK_5: 5,
  DRAWBOX_STROKEWIDTH_CLK_2: 2,
  DRAWBOX_POLY_STROKEWIDTH_VIEW: 10,
  DRAWBOX_POLY_STROKEWIDTH_THUMB: 1,
  DRAWBOX_SHADOWBLUR: 1,
  DRAWBOX_OPACITY: 0,
  DRAWBOX_OPACITY_INIT: 1,
  DRAWBOX_FADE_IN_OPACITY: 1,
  DRAWBOX_FADE_IN_DURATION_T1: 1,
  DRAWBOX_FADE_IN_DURATION: 2,
  RADIUS: 40,
  CIRCLE_RED: '#00F0FF',
  CIRCLE_DRAW_PREFIX: 'circle_',
  PROGRESSIVE_TRANSDURATION: 1000,
  PROGRESSIVE_FRAMERATE: 10,
  PROGRESSIVE_FRAME: 0,
  PROGRESSIVE_BLURRADIUS: 50,
  PROGRESSIVE_PERCENTAGE: 100,
  PROGRESSIVE_BLUR_RADIUS_L1: 0,
  PROGRESSIVE_BLUR_RADIUS_L2: 2,
  PROGRESSIVE_BLUR_RADIUS_L3: 50,
  PROGRESSIVE_BLUR_STAGE_WID: 540,
  PROGRESSIVE_BLUR_AJST_HEIGHT: 30,
  DRAWBOX_STROKE_RED: '#FF0000',
  DRAWBOX_FILL_RED: 'rgba(182, 20, 20, 0.12)',
};

export const STAGE_2D_CANVAS_COMMON_VIEW = {
  THUMB_STAGENAME: 'thumbnailStage',
  ANNOTATION_TYPE: 'annotation_2d',
  ANNOTATION_TAG: 'annotation',
  VALIDATE_TAG: 'validated',
  INVALIDATE_TAG: 'invalidate',
  AI_TAG: 'ai',
  PAGE_LIMIT: 10,
  RANGE_VALUE: 50,
  ZOOM_IN_OUT_SCALE_BY: 1.1,
  ZOOM_IN_SCALE_BY: 1.2,
  ZOOM_IN_SCALE_BY_T1: 0.1,
  ZOOM_OUT_SCALE_BY: 0.9,
  ZOOM_LEVEL_FACTOR: 1,
  ZOOM_LEVEL_INIT: 1,
  BG_FADE_IN_OPACITY: 1,
  BG_FADE_IN_DURATION_T1: 1,
  BG_FADE_IN_DURATION: 2,
  CONFIRM_BOX_ALERT: 'Are you sure you want to remove the box!',
  IMAGE_PAGE_LIMIT: 200,
  IMAGE_INDEX_KEY: 1,
  ANNOTATION_PAYLOAD_ADD: 'add',
  ANNOTATION_PAYLOAD_UPDATE: 'update',
  ANNOTATION_PAYLOAD_REMOVE: 'remove',
  CANVAS_MINIMIZE: 'minimize',
  CANVAS_MAXMIZE: 'maximize',
  CANVAS_CONTAINER: 'canvas-container',
  CANVAS_MSG_TITLE: 'Annotation canvas',
  CANVAS_COLLECTIONID: 'grid-gt-annotations',
  CANVAS_COLLECTION_TELCO: 'telco-equip-annotations',
  DISK_INSULATOR: 'disk_insulator',
  BROKEN_INSULATOR: 'broken_insulator',
  ARCHING_HORN: 'arching_horn_misaligned',
  ANTENNA: 'antenna',
  TRANSCEIVER: 'Transceiver',
  HEAD_FRAME_MOUNT: 'Head frame Mount',
  AVIATION_LIGHT: 'Aviation Light',
  SHELTER: 'Shelter',
  CURSOR_POINTER: 'pointer',
  CURSOR_MOVE: 'move',
  CURSOR_DEFAULT: 'default',
  CURSOR_CROSS: 'crosshair',
  CLIENT_RECT_DIVIDER: 2,
  CLIENT_RECT_DEGREE: 180,
  DRAW_RECT: 'Rect',
  DRAW_LINE: 'Line',
  IMAGE_LOADING: 'Canvas image is loading..',
  HORZ_RECT_THUMB_ADJUST: 2,
  VIEWER_LOADER_BLOCK: 'block',
  VIEWER_LOADER_NONE: 'none',
  IMAGE_PER_CHUNK: 10,
  POLYGON_REQUIRED_LENGTH: 6,
  SET_BGIMAGE_ZOOM_OUT: 0.1,
  DEL_CONFIRM_CONTENT: 'Are you sure you want to delete this?',
  DEL_CONFIRM_CONTENT_SNAP:
    'Are you sure you want to delete this snapshot image?',
  DEL_CONFIRM_TITLE: 'Delete Confirmation',
  DEL_CONFIRM_DEL_BTN: 'Delete',
  DEL_CONFIRM_CANCEL_BTN: 'Cancel',
  TAG_VERTICAL_VIEW: 'vertical',
  TAG_HORIZONTAL_VIEW: 'horizontal',
  GROUP_TAG_NAME: 'displayTag',
  CANVAS_VIEWER_REF: 'viewer_canvas_',
  CANVAS_DRAGGABLE_MIN: 40,
  CANVAS_DRAGGABLE_MAX: 70,
  DRAG_STAGE_WIDTH: 1024,
  DEFAULT_TEXT_LABEL_FONT_SIZE: 60,
};

export const CANVAS_CONTAINER = 'canvas-container';

export const DASHBOARD_RIGHT_PANEL_COMPONENTS = {
  header: true,
  filters: true,
  findingsTotal: true,
  findingByPriority: true,
  findingType: true,
  findingsByComponent: true,
  findingsByMonth: true,
  findingsByLaterals: true,
  findingsByRegionalOffice: true,
};

export const MISSION_ID_KEY = 'missionId';
export const ASSET_IDS_KEY = 'assetIds'

export const DRONOS_UTM_EVENTS = {
  MISSION_CLICK: 'dronos:utm:mission-click',
  SIDE_BAR_CLICK: 'dronos:utm:sidebar-click',
  TOGGLE_SIDE_MENU: 'dronos:sidebar-toggle',
};

export const DRONOS_UTM_EVENT_ICONS = {
  MISSION: 'mission',
};
export const IMAGE_RESOURCE_TYPES = {
  REPORT_COVER_IMAGE: 'report-cover-image',
  REPORT_LOGO: 'report-logo',
};

export const REPORT_PAGES_COUNT = {
  REPORT_COVER_PAGE_COUNT: 1,
  REPORT_SUMMARY_PAGE_COUNT: 2,
};

export const FINDING_TABLE_FIELDS = {
  ASSET:'asset',
  MISSION_ID:'missionId',
  SEVERITY_LABEL:'severityLabel',
  FINDING_LABEL:'findingLabel'
}