<pdf-page
  class="mt-3"
  [pdfPageOptions]="{
    showFooter: false,
    resetInternalStyles: true
  }"
>
  <div>
    @if(coverPageData?.cover?.url?.length){
    <img
      [src]="coverPageData.cover?.url"
      alt="cover-img"
      class="report-cover-image"
    />
    } @if(!coverPageData?.cover) {
    <img
      [src]="'/images/placeholders/report-cover-fallback.png' | assetUrl"
      alt="cover-img"
      class="report-cover-image"
    />
    }
  </div>
  <div class="cover-content">
    <div class="d-flex flex-column workspace-section">
      <span class="workspace-name-heading"> Inspection Report </span>
      <input class="workspace-name" [value]="workspaceName" (change)="onTitleChange($event)" />
    </div>
    <div class="report-meta">
      <div class="d-flex flex-column g-12">
        <span class="meta-text">Inspection Report For</span>
        @if(coverPageData?.logo?.url?.length) {
        <img
          [src]="coverPageData.logo?.url"
          alt="cover-img"
          class="report-logo-image"
        />
        } @if(coverPageData?.recipient?.length) {
        <span class="meta-text">{{ coverPageData?.recipient }}</span>
        }
      </div>
      <div class="d-flex flex-column g-12">
        <span class="meta-text">Created By</span>
        <span class="meta-text">{{ workspaceName }}</span>
      </div>
    </div>
  </div>
</pdf-page>
