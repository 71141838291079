<div class="left-panel-wrapper" *ngIf="showMissionPanel">
  <div class="left-panel">
    <div class="heading">
      <img
        [src]="'/images/collapse-icon.svg' | assetUrl"
        alt="collapse-arrow"
        (click)="hideMissionChartsPanel()"
        class="collapse-icon"
      />
      <div class="title">Analytics</div>
    </div>
    <div class="chart-container">
      <span class="chart-title">Completed Mission by Mission Objective</span>
      <donut-chart
        [dataSource]="_missionChartData"
        [chartTitle]="getChartTitle(_missionChartData)"
        [chartOptions]="chartConfig"
      ></donut-chart>
    </div>
    <div class="chart-container">
      <span class="chart-title">Drone Usage</span>
      <donut-chart
        [dataSource]="_userUsageChartData"
        [chartTitle]="getChartTitle(_userUsageChartData)"
        [chartOptions]="chartConfig"
      ></donut-chart>
    </div>
  </div>
</div>
<div class="show-panel-btn-wrapper" *ngIf="selectedIcon === 'mission'">
  <button (click)="showMissionChartsPanel()" class="show-panel-btn">
    Show Panel
  </button>
</div>
