import { Component, Input } from '@angular/core';
import {
  AuditChecklistPayload,
  FindingsDetailsEntity,
} from 'projects/dashboard-reporting/src/app/shared/interfaces';

@Component({
  selector: 'report-audit-checklist-page',
  templateUrl: './audit-checklist.component.html',
  styleUrls: ['./audit-checklist.component.scss'],
})
export class ReportAuditChecklistPageComponent {
  @Input() auditChecklist: AuditChecklistPayload;
  @Input() findingDetails?: FindingsDetailsEntity[];

  scrollToElement(findingLabelId: string) {
    const findingDetailPage = document.getElementById(findingLabelId);
    if (findingDetailPage) {
      findingDetailPage.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getAssociatedFindings(findingLabelId: string) {
    if (this.findingDetails) {
      const associatedFindings = this.findingDetails.filter(
        (item) => item.findingLabel === findingLabelId
      );
      return associatedFindings;
    }
    return [];
  }

  getFindingPageNo(findingId: string) {
    if (this.findingDetails) {
      const findingIndex = this.findingDetails?.findIndex(
        (item) => item._id === findingId
      );
      if (findingIndex != -1) {
        return findingIndex + 1;
      }
    }
    return '';
  }
}
