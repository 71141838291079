import { Component, ElementRef, ViewChild } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import {
  FindingsByPriority,
  FindingsDetails,
  FindingsReportFilters,
  LabelsMap,
  MissionDetails,
  ReportCoverPayload,
  ReportQueryFilters,
} from 'projects/dashboard-reporting/src/app/shared/interfaces';
import { FindingsChartService } from 'projects/dashboard-reporting/src/app/shared/services/charts/findings-charts.service';
import { MissionService } from 'projects/dashboard-reporting/src/app/shared/services/mission/mission.service';
import { LabelsMapService } from 'projects/dashboard-reporting/src/app/shared/services/labels-map/labels-map.service';
import { StoreService } from 'projects/dashboard-reporting/src/app/shared/services/store/store.service';
import { state } from '@app/utility';
import { InspectionFindingReportService } from 'projects/dashboard-reporting/src/app/shared/services/report/report.service';

@Component({
  selector: 'generate-mission-report-panel',
  templateUrl: './generate-mission-report.component.html',
  styleUrls: ['./generate-mission-report.component.scss'],
})
export class GenerateMissionReportComponent {
  constructor(
    private findingsChartService: FindingsChartService,
    private inspectionFindingReport: InspectionFindingReportService,
    private missionService: MissionService,
    private labelsMapService: LabelsMapService,
    private inspectionFindingReportService: InspectionFindingReportService,
    private storeService: StoreService,
  ) {}

  @ViewChild('content') content!: ElementRef;

  summaryChartsData: FindingsByPriority[] = [];
  missionId = '';
  _findingsDetails!: FindingsDetails;
  _missionDetails!: MissionDetails;
  isDataLoading = true;
  isGeneratingPdf = false;
  labelMap!: LabelsMap;
  assetLocationsMap: BehaviorSubject<string[]> = new BehaviorSubject(['']);
  _reportFilters: FindingsReportFilters;
  coverPageData: ReportCoverPayload;

  ngOnInit() {
    this._reportFilters = this.storeService.getReportFilters();
    this.getSummaryChartsData(
      this.parseFindingsReportFilters(this._reportFilters),
    );
    this.findingsDetails(this.parseFindingsReportFilters(this._reportFilters));
    this.getMissionDetails(
      this.parseFindingsReportFilters(this._reportFilters).missionIds?.[0] ??
        '',
    );
    this.getLabelName();
  }

  async getLabelName() {
    this.labelMap = await lastValueFrom(this.labelsMapService.getAllLabels());
  }

  async getSummaryChartsData(filters?: ReportQueryFilters) {
    this.isDataLoading = true;
    this.summaryChartsData = await lastValueFrom(
      this.findingsChartService.getFindingsData(filters),
    );
    this.isDataLoading = false;
  }

  async findingsDetails(filters?: ReportQueryFilters) {
    this._findingsDetails = await lastValueFrom(
      this.inspectionFindingReport.getFindingsWithDetails(filters),
    );
  }

  async getMissionDetails(missionId: string) {
    this._missionDetails = await lastValueFrom(
      this.missionService.getMissionDetails(missionId),
    );
  }

  async generatePdf() {
    try {
      this.isGeneratingPdf = true;
      this.isDataLoading = true;
      this.inspectionFindingReportService.exportFindingsPdfReport({
        inspectionFindingIds: [
          ...(this.storeService
            .getReportFilters()
            .inspectionFindings?.map((item: any) => item._id) ?? []),
        ],
        missionIds: this.parseFindingsReportFilters(this._reportFilters)
          .missionIds,
        coverImageResourceId: this.coverPageData.cover?.dataResourceId,
        logoResourceId: this.coverPageData.logo?.dataResourceId,
        recipientName: this.coverPageData.recipient,
        title:
          this.coverPageData.title ??
          state.getActiveWorkspaceEntity().workspace.name,
        ...this._reportFilters,
      });
    } catch {
    } finally {
      this.isGeneratingPdf = false;
      this.isDataLoading = false;
    }
  }

  trackByFn(index: number, item: any): number {
    return item._id;
  }

  onCoverDataChange(payload: ReportCoverPayload) {
    this.coverPageData = {
      ...payload,
    };
  }

  parseFindingsReportFilters(filters: FindingsReportFilters) {
    let reportQueryFilters: ReportQueryFilters = {};
    reportQueryFilters = {
      assetIds: filters.assets?.map((item) => item._id),
      componentIds: filters.components?.map((item) => item._id),
      inspectionFindingIds: filters.inspectionFindings?.map((item) => item._id),
      severityLabelIds: filters.severityLabels?.map((item) => item._id),
      missionIds: filters.missions?.map((item) => item._id),
    };
    return reportQueryFilters;
  }
}
