import { EnvironmentService } from '../envoirnments/environment.service';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl();

export class Routes {
  static readonly baseAddress = apiUrl;

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Reporting Service >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // =============================== Mission Charts Data =============================== //
  static readonly GET_STATS_BY_MISSION_OBJECTIVE = `${Routes.baseAddress}/report/mission-report/stats-by-mission-objective`;

  // =============================== Drone Usage Charts Data =============================== //
  static readonly TOTAL_MISSIONS_BY_DRONE = `${Routes.baseAddress}/report/mission-report/total-missions-by-drone`;

  // =============================== Findings By Severity =============================== //
  static readonly FINDINGS_BY_SEVERITY = `${Routes.baseAddress}/report/inspection-finding-report/findings-by-severity`;

  // =============================== Findings Stats By Month =============================== //
  static readonly FINDINGS_STATS_BY_MONTH = `${Routes.baseAddress}/report/inspection-finding-report/stats-by-month`;

  // =============================== Findings Stats By Asset =============================== //
  static readonly FINDINGS_STATS_BY_ASSET = `${Routes.baseAddress}/report/inspection-finding-report/stats-by-asset`;

  // =============================== Findings Stats By Site =============================== //
  static readonly FINDINGS_STATS_BY_SITE = `${Routes.baseAddress}/report/inspection-finding-report/stats-by-site`;

  // =============================== Findings Search Filters =============================== //
  static readonly FINDINGS_SEARCH_FILTERS = `${Routes.baseAddress}/report/inspection-finding-report/finding-search-filters`;

  // =============================== Findings Search Filters =============================== //
  static readonly FINDINGS_DETAILS = `${Routes.baseAddress}/report/inspection-finding-report/get-finding-details`;

  // =============================== Export PDF =============================== //
  static readonly GENERATE_PDF = `${Routes.baseAddress}/report/inspection-finding-report/generate-pdf`;

  // =============================== STATS BY COMPONENTS =============================== //
  static readonly STATS_BY_COMPONENTS = `${Routes.baseAddress}/report/inspection-finding-report/stats-by-components`;

  // =============================== LABELS MAP =============================== //
  static readonly LABELS_MAP = `${Routes.baseAddress}/report/inspection-finding-report/get-labels-map`;

  // =============================== STATS BY COMPONENTS =============================== //
  static readonly FINDINGS_LIST = `${Routes.baseAddress}/report/inspection-finding-report/inspection-findings-list`;

  // =============================== COMPONENTS LABEL MAP =============================== //
  static readonly COMPONENTS_MAP = `${Routes.baseAddress}/report/inspection-finding-report/get-components-map`;

  // =============================== ASSETS LIST =============================== //
  static readonly ASSETS_LIST = `${Routes.baseAddress}/report/inspection-finding-report/assets-list`;

  // =============================== SEVERITY LABEL MAP =============================== //
  static readonly SEVERITY_MAP = `${Routes.baseAddress}/report/inspection-finding-report/severity-labels-map`;

  // =============================== SEVERITY LABEL MAP =============================== //
  static readonly FINDING_TYPES_MAP = `${Routes.baseAddress}/report/inspection-finding-report/findingType-labels-map`;

  // =============================== SEVERITY LABEL MAP =============================== //
  static readonly GET_MISSIONS_LIST = `${Routes.baseAddress}/report/inspection-finding-report/missions-list`;

  // =============================== EXPORT PDF =============================== //
  static readonly EXPORT_FINDINGS_PDF = `${Routes.baseAddress}/report/export-pdf/inspection-findings`;

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< DataResource Service >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // =============================== GET DATA RESOURCE =============================== //
  static readonly GET_DATA_RESOURCE = `${Routes.baseAddress}/data-resource/data-resource`;

  // =============================== UPLOAD RESOURCE =============================== //
  static readonly UPLOAD_RESOURCE = `${Routes.baseAddress}/data-resource/storage`;

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Mission Service >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  static readonly GET_MISSION_DETAILS = `${Routes.baseAddress}/mission/data-proc-only/missions`;

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Template Service >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // =============================== Findings Search Filters =============================== //
  static readonly ASSET_TEMPLATE = `${Routes.baseAddress}/report/template/asset-template`;

  // =============================== Notification Service =============================== //
  static readonly SSE_LISTENER = `${Routes.baseAddress}/notification/workspace-notification/emitter`;

}
