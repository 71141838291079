import { Injectable } from '@angular/core';
import { state } from '@app/utility';


@Injectable({
  providedIn: 'root'
})
export class PermissionManagerService {
  permissions:string[] = [];
  
  constructor() {
    this.getPermissions();
  }

  getPermissions(){
    const userPermissions = state.getActiveWorkspaceEntity()?.role.permissions;
    return userPermissions;
  }

  isGranted(permission: string[]) {
    const userPermissions = this.getPermissions();
    const permissionFound = userPermissions.some((item:string) => permission.includes(item));
    return permissionFound;
  }
}
