<report-filter-panel
  (onCoverDataChange)="onCoverDataChange($event)"
></report-filter-panel>
<div class="content">
  <div class="pdf-ctrl-panel">
    <button
      class="export-btn"
      (click)="generatePdf()"
      [disabled]="isGeneratingPdf"
    >
      {{ isGeneratingPdf ? "Exporting..." : "Export as Pdf" }}
    </button>
  </div>
  <div class="view-pdf-section mt-3" #content>
    <report-cover-page
      [coverPageData]="coverPageData"
      (onReportTitleChange)="onCoverDataChange($event)"
    ></report-cover-page>
    <report-summary-page
      [summaryChartsData]="summaryChartsData"
      [missionDetails]="_missionDetails"
      [assetsList]="_findingsDetails?.relatedEntities?.assetsMap"
    ></report-summary-page>
    @for(auditChecklistEntity of this._findingsDetails?.relatedEntities?.auditChecklist?.havingFindings;track auditChecklistEntity._id
    ){
    <report-audit-checklist-page
      [auditChecklist]="auditChecklistEntity"
      [findingDetails]="_findingsDetails?.findingDetails"
    ></report-audit-checklist-page>
    }
    <div
      *ngFor="
        let finding of _findingsDetails?.findingDetails;
        let i = index;
        trackBy: trackByFn
      "
      [id]="finding.findingLabel"
    >
      <findings-detail
        class="mt-3"
        [findingDetails]="finding"
        [relatedEntitiesMap]="_findingsDetails.relatedEntities"
        [pageNumber]="i"
        [totalPages]="_findingsDetails.findingDetails.length"
        [missionDetails]="_missionDetails"
        [itemIndex]="i"
        [labelMap]="labelMap"
      ></findings-detail>
    </div>
  </div>
  <ng-template [ngIf]="isDataLoading">
    <div class="bg-shadow"></div>
    <div class="loader" *ngIf="!isGeneratingPdf">
      <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div *ngIf="isGeneratingPdf">
      <div class="text-only-loader">Generating Pdf... Please wait</div>
    </div>
  </ng-template>
</div>
