import { Injectable } from '@angular/core';
import { FindingsReportFilters } from '../../interfaces';
@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private reportFilters: FindingsReportFilters = {};

  setReportFilters(data: FindingsReportFilters) {
    this.reportFilters = {
      ...this.reportFilters,
      ...data,
    };
  }

  getReportFilters(): FindingsReportFilters {
    return this.reportFilters;
  }
}
