<div
  class="page"
  [ngClass]="{ 'reset-dimensions': pdfPageOptions.resetInternalStyles }"
>
  <ng-content></ng-content>
  @if(pdfPageOptions.showFooter) {
  <div class="page-footer d-flex justify-content-between align-items-center">
    <div class="meta-data">
      <span>
        {{ pdfPageOptions.pageNumber }} / {{ pdfPageOptions.totalPages }}
      </span>
      <span> {{ pdfPageOptions.title }} ( {{ getCurrentDate() }} ) </span>
    </div>
    <img [src]="'/images/dronos-icon.svg' | assetUrl" alt="dronos-icon" />
  </div>
  }
</div>
