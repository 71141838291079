import { Injectable } from '@angular/core';
import { ICanvasImageProperty } from '../../interfaces';
import { HelperService } from '../helpers/helper.service';
@Injectable({
  providedIn: 'root',
})
export class AnnotationVisualCanvasService {
  constructor(private helperService: HelperService) {}

  getPolygonCoordinatesBasedOnImageAxis(
    imageProperty: ICanvasImageProperty,
    pointCoordinate: any
  ) {
    const displayedImageWidth = imageProperty.width;
    const displayedImageHeight = imageProperty.height;
    let listPoints = this.helperService.getSingleFlatArray(pointCoordinate);
    const polygonPoints2dAr: [number, number][] = [];
    for (let i = 0; i < listPoints.length; i += 2) {
      const x = Number(listPoints[i] * displayedImageWidth);
      const y = Number(listPoints[i + 1] * displayedImageHeight);
      polygonPoints2dAr.push([Number(x), Number(y)]);
    }
    return polygonPoints2dAr;
  }
}
