import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { lastValueFrom } from 'rxjs';
import {
  ASSET_IDS_KEY,
  DRONOS_UTM_EVENTS,
  DRONOS_UTM_EVENT_ICONS,
  MISSION_ID_KEY,
  SEVERITY_LEVELS,
} from 'projects/dashboard-reporting/src/app/shared/constants/index.constants';
import {
  ChartData,
  FindingsChartOptions,
  DonutChartOptions,
  ReportQueryFilters,
  SingleBarChartOptions,
  IDashBoardRightPanelSections,
  IUTMMissionEvent,
} from 'projects/dashboard-reporting/src/app/shared/interfaces';
import {
  FindingEntity,
  FindingsByPriority,
} from 'projects/dashboard-reporting/src/app/shared/interfaces/chart-api.interface';
import { FindingsChartService } from 'projects/dashboard-reporting/src/app/shared/services/charts/findings-charts.service';
import { HelperService } from 'projects/dashboard-reporting/src/app/shared/services/helpers/helper.service';
import { LabelsMapService } from 'projects/dashboard-reporting/src/app/shared/services/labels-map/labels-map.service';
import { TemplateService } from 'projects/dashboard-reporting/src/app/shared/services/templates/template.service';
import { CHART_TOOLTIP } from 'projects/dashboard-reporting/src/app/shared/constants/charts.constants';

@Component({
  selector: 'app-asset-owner-panel',
  templateUrl: './asset-owner-panel.component.html',
  styleUrls: ['./asset-owner-panel.component.scss'],
})
export class AssetOwnerPanelComponent implements OnInit, OnChanges {
  @Input() showDashboard = false;
  @Input() missionId: string = '';
  @Input() assetIds: string[] = []
  @Input() visiableComponents: IDashBoardRightPanelSections = {
    header: false,
    filters: false,
    findingsTotal: false,
    findingByPriority: false,
    findingType: false,
    findingsByComponent: false,
    findingsByMonth: false,
    findingsByLaterals: false,
    findingsByRegionalOffice: false,
  };
  @Input() customStyle = {
    componentStyles: {}, findingsTypeStyles: {}
  };
  selectedIcon = '';
  _severityFindingsChartData!: ChartData[];
  _totalFindingsChartData!: number;
  _criticalFindingsChartData!: number;
  _findingsByTypeChartData!: ChartData[];
  _findingsByMonthChartData!: ChartData[];
  _lateralsChartData!: ChartData[];
  _regionalChartData!: ChartData[];
  _statsByComponents!: ChartData[];
  priorityChartConfig!: DonutChartOptions;
  findingsTypeChartConfig!: SingleBarChartOptions;
  selectedMissionId = '';
  totalFindingsChartOptions!: FindingsChartOptions;
  criticalFindingsChartOptions!: FindingsChartOptions;
  queryFilters: ReportQueryFilters = {
    startDate: undefined,
    endDate: undefined,
  };
  dashboardFilters = {
    startDate: '',
    endDate: '',
  };
  assetTemplate: any;
  UTMEventsIcons = DRONOS_UTM_EVENT_ICONS;

  constructor(
    private findingsChartService: FindingsChartService,
    public helperService: HelperService,
    private labelsMapService: LabelsMapService,
    private templateService: TemplateService
  ) {}

  showDashboardChartsPanel() {
    this.showDashboard = true;
  }

  hideDashboardChartsPanel() {
    this.showDashboard = false;
  }
  ngOnInit() {
    this.listenMissionSelection();
    window.addEventListener(DRONOS_UTM_EVENTS.SIDE_BAR_CLICK, (event: any) => {
      this.selectedIcon = event.detail.icon;
      if (event.detail.icon == this.UTMEventsIcons.MISSION)
        this.showDashboard = true;
      else this.showDashboard = false;
    });
    //listen sidebar enable/disable
    window.addEventListener(
      DRONOS_UTM_EVENTS.TOGGLE_SIDE_MENU,
      (event: any) => {
        this.showDashboard = event.detail.toggle_type;
      }
    );
    //end listen sidebar enable/disable
    this.priorityChartConfig = {
      legendPosition: 'right',
      showLabelLine: false,
      showLabels: true,
      align: 'left',
      tooltipText: CHART_TOOLTIP.NAME_WITH_PERCENTAGE,
      labelPosition: 'inner',
      labelFontSize: '8px',
    };
    this.findingsTypeChartConfig = {
      legendOrientation: 'vertical',
      showLegend: true,
      legendScroll: true
    };
    this.totalFindingsChartOptions = {
      chartHeading: 'Total Findings',
      showIcon: false,
      darkBg: true,
    };
    this.criticalFindingsChartOptions = {
      chartHeading: 'Critical Findings',
      showIcon: true,
      darkBg: true,
    };

    // API calls for charts data
    this.setSeverityChartData({ missionIds: this.missionId ? [this.missionId] : [], assetIds: this.assetIds });
    if (this.visiableComponents.findingsByMonth) {
      this.setFindingsByMonthData();
    }
    if (this.visiableComponents.findingsByLaterals) {
      this.getAssetTemplates()
        .then((resp: any) => {
          this.setStatsByAssetData(resp[1].templateFields[0].name);
        })
        .catch(() => {});
    }
    if (this.visiableComponents.findingsByRegionalOffice) {
      this.setStatsBySiteData();
    }
    this.setStatsByComponents({ missionIds: this.missionId ? [this.missionId] : [], assetIds: this.assetIds });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // API call on missionId change
    if (
      (changes[MISSION_ID_KEY] && !changes[MISSION_ID_KEY].firstChange) || 
      (changes[ASSET_IDS_KEY] && !changes[ASSET_IDS_KEY].firstChange)
    ) {
      this.setSeverityChartData({ missionIds: this.missionId ? [this.missionId] : [], assetIds: this.assetIds });
      this.setStatsByComponents({ missionIds: this.missionId ? [this.missionId] : [], assetIds: this.assetIds });
    }
  }

  async setSeverityChartData(queryFilters?: ReportQueryFilters) {
    try {
      const chartData = await lastValueFrom(
        this.findingsChartService.getFindingsData(queryFilters)
      );

      const eChatData: ChartData[] = [];
      for (const item of chartData) {
        eChatData.push({
          name: await this.labelsMapService.getLabelName(item.severityType),
          value: item.totalCount,
        });
      }
      this._severityFindingsChartData =
        this.helperService.sortArrayInDescendingOrder(eChatData);
      this._findingsByTypeChartData =
        this.helperService.sortArrayInDescendingOrder(
          await this.parseFindingsTypeChartData(chartData)
        );
      this._totalFindingsChartData =
        this.helperService.getSumOfTotalCount(chartData);

      // Getting Critical Findings Total is nothing but Severity Type <High> Total Count
      this._criticalFindingsChartData =
        eChatData.find(
          (item: ChartData) =>
            item?.name?.toLowerCase() === SEVERITY_LEVELS.HIGH
        )?.value ?? 0;
    } catch (error) {}
  }

  async setFindingsByMonthData(queryFilters?: ReportQueryFilters) {
    this._findingsByMonthChartData = await lastValueFrom(
      this.findingsChartService.getFindingsByMonth(queryFilters)
    );
  }

  async setStatsByAssetData(
    hierarchyLevelName: string,
    queryFilters?: ReportQueryFilters
  ) {
    this._lateralsChartData = await lastValueFrom(
      this.findingsChartService.getStatsByAsset(
        hierarchyLevelName,
        queryFilters
      )
    );
  }

  async setStatsBySiteData(queryFilters?: ReportQueryFilters) {
    this._regionalChartData = await lastValueFrom(
      this.findingsChartService.geStatsBySite(queryFilters)
    );
  }

  async parseFindingsTypeChartData(chartData: FindingsByPriority[]) {
    const findingTypeCounts: ChartData[] = [];
    chartData
      .filter(
        async (item) =>
          (
            await this.labelsMapService.getLabelName(item.severityType)
          ).toLowerCase() == SEVERITY_LEVELS.HIGH
      )
      .forEach((item: any) => {
        item.findings.forEach(async (finding: FindingEntity) => {
          const { findingType, count } = finding;
          const itemIndex = findingTypeCounts.findIndex(
            (entity: ChartData) => entity.name == findingType
          );
          if (itemIndex != -1) {
            findingTypeCounts[itemIndex] = {
              ...findingTypeCounts[itemIndex],
              value: findingTypeCounts[itemIndex].value + count,
            };
          } else {
            findingTypeCounts.push({
              name: await this.labelsMapService.getLabelName(findingType),
              value: count,
            });
          }
        });
      });
    return findingTypeCounts;
  }

  handleMissionIdChanges = (missionId: string) => {
    this.selectedMissionId = missionId;
    if (missionId) {
      this.setSeverityChartData({ missionIds: missionId ? [missionId] : [] });
      this.setStatsByComponents({ missionIds: missionId ? [missionId] : [] });
    } else {
      this.setSeverityChartData();
      this.setStatsByComponents();
    }
  };

  listenMissionSelection() {
    window.addEventListener(
      DRONOS_UTM_EVENTS.MISSION_CLICK,
      (event: IUTMMissionEvent) => {
        this.handleMissionIdChanges(event?.detail?.missionId ?? '');
      }
    );
  }

  onDateChange(event: any, key: string) {
    // @ts-ignore
    this.dashboardFilters[key] = event == '' ? event : event.target.value;
    if (
      this.dashboardFilters['startDate']?.length &&
      this.dashboardFilters['endDate']?.length
    ) {
      this.queryFilters['startDate'] = this.formatDate(
        this.dashboardFilters['startDate']
      );
      this.queryFilters['endDate'] = this.formatDate(
        this.dashboardFilters['endDate']
      );
      this.RecallChartData(this.queryFilters);
    }
    if (
      !this.dashboardFilters['startDate']?.length &&
      !this.dashboardFilters['endDate']?.length
    ) {
      this.RecallChartData({});
    }
  }

  async getAssetTemplates() {
    this.assetTemplate = await lastValueFrom(
      this.templateService.getAssetTemplate()
    );
  }

  formatDate(date: string) {
    const inputDate = new Date(date);
    return inputDate.toISOString().slice(0, 16);
  }

  RecallChartData(filters: any) {
    this.setSeverityChartData(filters);
    this.setFindingsByMonthData(filters);
    this.setStatsBySiteData(filters);
  }

  async setStatsByComponents(queryFilters?: ReportQueryFilters) {
    this._statsByComponents = await lastValueFrom(
      this.findingsChartService.getComponentsStats(queryFilters)
    );
  }
}
