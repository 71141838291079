import { Component, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ChartData } from 'projects/dashboard-reporting/src/app/shared/interfaces';
import { HelperService } from 'projects/dashboard-reporting/src/app/shared/services/helpers/helper.service';

@Component({
  selector: 'app-scatter-chart',
  templateUrl: './scatter-chart.component.html',
  styleUrls: ['./scatter-chart.component.scss'],
})
export class ScatterChartComponent {
  echartsOptions: EChartsOption = {};
  @Input() dataSource: ChartData[] = [];

  constructor(private helperServive: HelperService) {}

  ngOnInit() {
    this.echartsOptions = {
      grid: {
        show: false,
        top:40
      },
      tooltip: {
        trigger: 'item',
        formatter: `{b}: {c}`,
        padding: 5,
        backgroundColor: '#000',
        textStyle: {
          color: '#FFF',
        },
      },
      xAxis: {
        data: this.getChartLabel(),
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: '#C8C8C8',
        },
      },
      yAxis: {
        splitLine: {
          lineStyle: {
            color: '#6E7079',
          },
        },
        axisLabel: {
          color: '#C8C8C8',
        },
      },
      series: [
        {
          data: this.getLineChartData().map((point) => ({
            value: point,
            itemStyle: {
              color: `#${this.helperServive.getRandomColor()}`,
            },
          })),
          type: 'scatter',
        },
      ],
    };
  }
  onChartClick(event: any) {}

  getLineChartData() {
    const tempArr: number[] = [];
    for (const item of this.dataSource) {
      tempArr.push(item.value);
    }
    return tempArr;
  }

  getChartLabel() {
    const labels: string[] = [];
    for (const item of this.dataSource) {
      labels.push(item.name);
    }
    return labels;
  }
}
