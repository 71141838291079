import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FindingsDetails,
  InspectionFindingFilters,
  InspectionFindingsEntity,
  RelatedEntities,
  ReportQueryFilters,
} from '../../interfaces';
import { HelperService } from '../helpers/helper.service';
import { Routes } from '../routes/api-routes';
import { lastValueFrom } from 'rxjs';
import { FindingsService } from '../findings/findings.service';
import { FindingsListFilters } from '../../interfaces/findings-dashboard.interface';
import * as XLSX from 'xlsx';
import { LabelsMapService } from '../labels-map/labels-map.service';

@Injectable({
  providedIn: 'root',
})
export class InspectionFindingReportService {
  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService,
    private findingsService: FindingsService,
    private labelMapService: LabelsMapService
  ) {}

  getInspectionFilters(missionId: string) {
    return this.httpClient
      .post<InspectionFindingFilters>(Routes.FINDINGS_SEARCH_FILTERS, {
        missionId,
      })
      .pipe();
  }

  getFindingsWithDetails(filters?: ReportQueryFilters) {
    return this.httpClient
      .post<FindingsDetails>(Routes.FINDINGS_DETAILS, {
        ...this.helperService.filterObject(filters),
      })
      .pipe();
  }

  //report-pdf-export-flow
  getFindingsPdfReport(filters?: ReportQueryFilters) {
    return this.httpClient
      .post<any>(
        Routes.EXPORT_FINDINGS_PDF,
        {
          ...this.helperService.filterObject(filters),
        },
        {
          observe: 'response',
          // @ts-ignore
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
          },
        }
      )
      .pipe();
  }

  async exportFindingsPdfReport(filters?: ReportQueryFilters) {
    await lastValueFrom(this.getFindingsPdfReport(filters));
    // this.handlePdfDownload(report);
  }

  handlePdfDownload(response: any) {
    try {
      const blob = new Blob([response.body], { type: 'application/pdf' });
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'findings-report.pdf';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {}
  }

  //excel-report-flow
  async exportFindingsExcelReport(filters?: FindingsListFilters) {
    const report: {
      allFindings: InspectionFindingsEntity[];
      relatedEntities: RelatedEntities;
    } = await this.findingsService.getAllFindings(filters);
    this.generateExcelFile(
      this.parseFindingsDataForExcel(report.allFindings, report.relatedEntities)
    );
  }

  parseFindingsDataForExcel(
    report: InspectionFindingsEntity[],
    relatedEntitiesMap: RelatedEntities
  ) {
    const retrieveItemFromEntityMap = (key: string, id: string): any => {
      if (relatedEntitiesMap && id) {
        const respectedItem: any =
          relatedEntitiesMap[key as keyof RelatedEntities];
        return respectedItem[id];
      }
      return '';
    };

    const data: any = [];
    report.forEach((findingEntity) => {
      data.push({
        Asset: retrieveItemFromEntityMap('assetsMap', findingEntity.assetId)
          ?.assetName,
        'Finding Name': findingEntity.findingLabel?.name ?? '',
        'Findings Type': findingEntity.findingType ?? '',
        Severity: findingEntity?.severityLabel?.name ?? '',
        Component:
          retrieveItemFromEntityMap('componentsMap', findingEntity?.componentId)
            ?.componentName ?? '',
        Mission:
          retrieveItemFromEntityMap('missionsMap', findingEntity.missionId)
            ?.trackingId ?? '',
        'Created At':
          this.helperService.parseDate(findingEntity.createdAt, true) ?? '',
        'Updated At':
          this.helperService.parseDate(findingEntity.updatedAt, true) ?? '',
        Remarks: findingEntity.description ?? '',
      });
    });
    return data;
  }

  generateExcelFile(data: any[]) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'findings_data.xlsx');
  }
}
