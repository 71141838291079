import { Injectable } from '@angular/core';
import { environment } from 'projects/dashboard-reporting/src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EnvironmentService {
  getApiUrl(): string {
    return environment.apiHost;
  }
}
