<pdf-page
  class="findinds-detail-page mt-3"
  [pdfPageOptions]="{
    title: 'Image Finding',
    pageNumber: pageNumber + 1,
    totalPages: totalPages,
    showFooter: true
  }"
>
  <div class="d-flex justify-content-between align-items-center">
    <span class="component-heading">Finding</span>
    <span class="report-no">Report</span>
  </div>
  <div class="d-flex mt-5 mb-4">
    <app-visual-annotation-panel
      [backgroundImageSrc]="
        getDataFromSiteMap(
          'dataResourcesMap',
          findingDetails.annotations[0]?.dataResourceId
        )?.storage?.files[0]?.preSignedUrl
      "
      [dataCoordinates]="findingDetails.annotations"
      [stageProperty]="{
        height: 462,
        width: 440,
        id:
          findingDetails.name.split('')[0] +
          findingDetails.annotations[0]?._id +
          itemIndex
      }"
    ></app-visual-annotation-panel>
    <div class="component-meta">
      <div class="d-flex flex-column g-16">
        <div class="d-flex flex-column">
          <span class="component-id-text">Finding Type</span>
          <span class="component-id mt-2">{{
            findingDetails.findingType
          }}</span>
        </div>
        <div class="d-flex flex-column">
          <span class="meta-sub-heading">Finding Name</span>
          <span class="component-name mt-2">{{
            getLabelName(findingDetails.findingLabel)
          }}</span>
        </div>
        <div class="d-flex flex-column">
          <span class="meta-sub-heading">Severity</span>
          <span class="component-name mt-2">{{
            getLabelName(findingDetails.severityLabel)
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-between align-items-start g-13">
    <div class="d-flex flex-column w-50 g-13">
      <div class="report-meta-box">
        <div class="d-flex flex-column g-4">
          <span class="meta-sub-heading">Asset Name</span>
          <span class="meta-sub-value">{{
            getDataFromSiteMap("assetsMap", findingDetails.assetId)?.assetName
          }}</span>
        </div>
        <div class="d-flex flex-column mt-3 g-4">
          <span class="meta-sub-heading">Asset Id</span>
          <span class="meta-sub-value"
            >{{
              getDataFromSiteMap("assetsMap", findingDetails.assetId)
                ?.externalClientAssetId
            }}
          </span>
        </div>
      </div>
      <div class="report-meta-box">
        <div class="d-flex flex-column g-4">
          <span class="meta-sub-heading">Mission Name</span>
          <span class="meta-sub-value">{{
            missionDetails?.objective?.name
          }}</span>
        </div>
        <div class="d-flex flex-column mt-3 g-4">
          <span class="meta-sub-heading">Finding Location</span>
          <span class="meta-sub-value"
            >{{ this.getFindingLocation(findingDetails) }}
          </span>
        </div>
        <div class="d-flex flex-column mt-3 g-4">
          <span class="meta-sub-heading">Captured Time</span>
          <span class="meta-sub-value"
            >{{
              getParsedDate(
                getDataFromSiteMap(
                  "dataResourcesMap",
                  findingDetails.annotations[0]?.dataResourceId
                )?.createdAt
              ) ?? "N/A"
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="comments-box">
      <span class="comments-heading">Comments</span>
      <div>No Comments yet.</div>
    </div>
  </div>
  <div class="recommendation-section">
    <span class="heading">Recommendation</span>
    <div class="text">
      {{ findingDetails?.description }}
    </div>
  </div>
</pdf-page>
