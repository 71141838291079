import { NgModule } from '@angular/core';
import { SharedModule } from 'projects/dashboard-reporting/src/app/shared/shared.module';
import { AssetOwnerPanelComponent } from './components/asset-owner-chart-panel/asset-owner-panel.component';
import { MissionChartsPanelComponent } from './components/pilot-mission-charts-panel/mission-charts-panel.component';

@NgModule({
  imports: [SharedModule],
  declarations: [AssetOwnerPanelComponent, MissionChartsPanelComponent],
  exports: [AssetOwnerPanelComponent, MissionChartsPanelComponent],
})
export class DashboardPanelsModule {}
