<div style="width: 100%; height: 100%">
  <ag-grid-angular
    [rowData]="dataSource"
    style="width: 100%; height: 100%"
    class="ag-theme-alpine"
    [columnDefs]="columns"
    [headerHeight]="81"
    rowSelection="multiple"
    [suppressRowTransform]="true"
    (selectionChanged)="onSelectionChanged($event)"
    #dataTable
  >
  </ag-grid-angular>
  @if(dataSource?.length){
  <pagination
    [totalRecords]="totalRecords"
    (pageChange)="pageChange($event)"
    [currentPage]="currentPage"
  ></pagination>
  }
</div>
