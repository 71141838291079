import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent {
  @Input() options: any[] = [];
  @Input() placeholder?: string = '';
  @Input() localSearch?: boolean = true;
  @Output() selectionChange = new EventEmitter<string[]>();
  @Output() onSearch = new EventEmitter<string>();
  @Output() refetchData = new EventEmitter<void>();

  selectedOptions: any[] = [];
  isDropdownOpen = false;
  selectAll = false;
  dropdownOptions: any = [];
  dimensions: any = {};
  searchText = '';

  @ViewChild('dropdownElement') dropdownElement: ElementRef;
  @ViewChild('myElement', { static: false }) myElement: ElementRef;

  calculateElementPosition() {
    const element = this.myElement.nativeElement;

    const rect = element.getBoundingClientRect();

    const top = rect.top + window.scrollY;
    const left = rect.left + window.scrollX;
    this.dimensions = {
      ...this.dimensions,
      top: `${top + 30}px`,
      left: `${left}px`,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options'] && !changes['options'].firstChange) {
      this.dropdownOptions = [
        ...this.options.map((option: any) => ({
          ...option,
          checked: false,
        })),
      ];
    }
  }

  onSearchItem(event: any) {
    if (this.localSearch && event.target.value?.length) {
      this.searchText = event.target.value;
    } else {
      this.onSearch.emit(event.target.value);
    }
  }

  isScrollAtBottom(element: HTMLElement): boolean {
    return element.scrollHeight - element.scrollTop === element.clientHeight;
  }

  onOptionChange(option: any) {
    if (this.selectedOptions.find((item) => item.id == option.id)) {
      this.selectedOptions = this.selectedOptions.filter(
        (item) => item.id !== option.id,
      );
      this.options = this.options.map((optionEntity) => {
        if (optionEntity.id == option.id) {
          return {
            ...optionEntity,
            checked: false,
          };
        } else {
          return {
            ...optionEntity,
          };
        }
      });
    } else {
      this.selectedOptions.push(option);
    }
    if (this.selectedOptions.length == this.options.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
    this.selectionChange.emit(
      this.selectedOptions.map((item) => {
        return item.id;
      }),
    );
  }

  onAllSelect() {
    if (this.selectAll) {
      this.dropdownOptions = this.dropdownOptions.map(
        (
          option: Array<{
            id: string;
            label: string;
          }>,
        ) => ({
          ...option,
          checked: true,
        }),
      );
      this.selectedOptions = this.dropdownOptions;
      this.selectionChange.emit(
        this.dropdownOptions.map((item: any) => {
          return item.id;
        }),
      );
    } else {
      this.dropdownOptions = this.dropdownOptions.map((option: any) => ({
        ...option,
        checked: false,
      }));
      this.selectedOptions = [];
      this.selectionChange.emit([]);
    }
  }

  toggleDropdown() {
    this.calculateElementPosition();
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      if (this.dropdownElement) {
        this.dropdownElement.nativeElement.addEventListener('scroll', () => {
          if (
            this.isScrollAtBottom(this.dropdownElement.nativeElement) &&
            !this.localSearch
          ) {
            this.dropdownElement.nativeElement.scrollTop =
              this.dropdownElement.nativeElement.scrollHeight -
              this.dropdownElement.nativeElement.clientHeight -
              40;
            this?.refetchData?.emit();
          }
        });
      }
    } else {
      this.dropdownElement.nativeElement.removeEventListener('click');
    }
  }

  getSelectedFiltersCount() {
    return this.selectedOptions.length;
  }

  filteredOptions() {
    if (this.localSearch && this.searchText.length) {
      return this.dropdownOptions.filter((item: any) =>
        item.label.toLowerCase().includes(this.searchText),
      );
    } else {
      return this.dropdownOptions;
    }
  }
}
