import { Component, Input, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import { BarChartOptions, ChartData } from 'projects/dashboard-reporting/src/app/shared/interfaces';
import { HelperService } from 'projects/dashboard-reporting/src/app/shared/services/helpers/helper.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent {
  echartsOptions: EChartsOption = {};
  @Input() dataSource: ChartData[] = [];
  @Input() chartTitle = '';
  @Input() chartOptions!: BarChartOptions;
  @Input() findingsChartLabels: Array<string> = [];

  constructor(private helperService: HelperService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['findingsChartLabels'] && this.findingsChartLabels.length) {
      this.echartsOptions = {
        ...this.echartsOptions,
        yAxis: {
          ...this.echartsOptions.yAxis,
          // @ts-ignore
          data: changes['findingsChartLabels'].currentValue,
        },
      };
    }

    if (changes['dataSource'] && !changes['dataSource'].firstChange) {
      this.echartsOptions = {
        ...this.echartsOptions,
        // @ts-ignore
        series: [
          ...this.parsedDataResource(changes['dataSource'].currentValue),
        ],
      };
    }
  }

  ngOnInit() {
    this.echartsOptions = {
      title: {
        text: this.chartTitle,
        top: '30px',
        textStyle: {
          color: this.chartOptions.textColor ?? '#FFF',
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: `{b}: {c}`,
        padding: 5,
        backgroundColor: '#FFF',
        textStyle: {
          color: this.chartOptions.textColor ?? '#FFF',
        },
      },
      legend: {
        show: false,
      },
      grid: {
        containLabel: true,
        left: 0,
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        axisLabel: {
          color: '#29292A',
        },
      },
      yAxis: {
        type: 'category',
        data: this.findingsChartLabels,
        axisLabel: {
          color: '#29292A',
        },
      },
      // @ts-ignore
      series: [...this.parsedDataResource(this.dataSource)],
    };
  }
  onChartClick(event: any) {}

  parsedDataResource(data: ChartData[]) {
    const tempArr = [];
    for (const item of data) {
      tempArr.push({
        value: item.value,
        itemStyle: {
          color: `#${this.helperService.getRandomColor()}`,
        },
        label: {
          show: true,
          position: 'right', // Adjust the position as needed (e.g., 'insideRight')
          formatter: '{c}', // Display the value of each bar
        },
      });
    }
    return [
      {
        data: [...tempArr],
        type: 'bar',
      },
    ];
  }
}
