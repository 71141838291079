import { Component, Input, SimpleChanges } from '@angular/core';
import type { EChartsOption } from 'echarts';
import {
  ChartData,
  DonutChartOptions,
} from 'projects/dashboard-reporting/src/app/shared/interfaces';
import { getLegendRightConfigForCharts, getTooltipConfigsForCharts } from '../../../utils/chart-helpers';

@Component({
  selector: 'donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent {
  echartsOptions: EChartsOption = {};
  @Input() dataSource: ChartData[] = [];
  @Input() chartTitle = '';
  @Input() chartOptions!: DonutChartOptions;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['dataSource'] &&
      changes['chartTitle'] &&
      !changes['dataSource'].firstChange &&
      !changes['chartTitle'].firstChange
    ) {
      this.echartsOptions = {
        ...this.echartsOptions,
        legend: this.getEchartLegend(),
        series: [
          {
            // @ts-ignore
            ...this.echartsOptions.series[0],
            data: [...changes['dataSource'].currentValue],
          },
        ],
        title: {
          ...this.echartsOptions.title,
          text: changes['chartTitle']?.currentValue,
        },
      };
    }
  }

  ngOnInit() {
    this.echartsOptions = {
      title: {
        text: this.chartTitle,
        left: 'center',
        top: 'center',
        textStyle: {
          color: this.chartOptions.textColor ?? '#FFF',
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: getTooltipConfigsForCharts(this.chartOptions),
        padding: 5,
        backgroundColor: '#000',
        textStyle: {
          color: '#FFF',
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          data: this.dataSource,
          selectedMode: 'single',
          label: {
            show: this.chartOptions?.showLabels ?? true,
            position: this.chartOptions?.labelPosition ?? 'outer',
            formatter: (params: any) => {
              const lines = params.name.split(' ');
              return this.chartOptions.labelPosition == 'inner'
                ? `${Math.round(params.percent)}%`
                : `${lines.join('\n')}: \n ${params.percent}%`;
            },
            color: this.chartOptions.textColor ?? '#FFF',
            distanceToLabelLine: 0.1,
            fontSize: this.chartOptions.labelFontSize,
            fontWeight: 500,
          },
          labelLine: {
            show: this.chartOptions?.showLabelLine ?? true,
          },
        },
      ],
    };
    this.setLegendOptions();
    this.alignChart();
  }
  onChartClick(event: any) {}

  setLegendOptions() {
    if (this.chartOptions?.legendPosition == 'right') {
      this.echartsOptions.legend = this.getEchartLegend();
      return;
    } else if (this.chartOptions?.legendPosition == 'bottom') {
      this.echartsOptions.legend = {
        ...this.echartsOptions.legend,
        left: '20px',
        bottom:'20px',
        type: 'scroll',
        textStyle: {
          color: this.chartOptions.textColor ?? '#FFF',
        },
        icon: 'circle',
      };
      return;
    }
    this.echartsOptions.legend = {
      ...this.echartsOptions.legend,
      orient: 'horizontal',
      bottom: '10px',
      textStyle: {
        color: this.chartOptions.textColor ?? '#FFF',
      },
    };
  }

  alignChart() {
    if (this.chartOptions?.align == 'left') {
      this.echartsOptions = {
        ...this.echartsOptions,
        series: [
          {
            // @ts-ignore
            ...this.echartsOptions.series[0],
            radius: ['35%', '60%'],
            center: ['25%', '50%'],
          },
        ],
        title: {
          ...this.echartsOptions.title,
          left: '22%',
        },
      };
    }
  }

  getEchartLegend() {
    return {
      ...this.echartsOptions.legend,
      ...getLegendRightConfigForCharts(this.chartOptions, this.dataSource)
    }
  }
}
