import { Component, Input, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ChartData, SingleBarChartOptions } from 'projects/dashboard-reporting/src/app/shared/interfaces';
import { COLOR } from '../../../constants/colors.constants';
import { LEGEND_TYPE } from '../../../constants/charts.constants';

@Component({
  selector: 'app-single-bar-chart',
  templateUrl: './single-bar-chart.component.html',
  styleUrls: ['./single-bar-chart.component.scss'],
})
export class SingleBarChartComponent {
  echartsOptions: EChartsOption = {};
  @Input() dataSource: ChartData[] = [];
  @Input() chartTitle = '';
  @Input() chartOptions!: SingleBarChartOptions;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource'] && !changes['dataSource'].firstChange) {
      this.echartsOptions = {
        ...this.echartsOptions,
        title: {
          ...this.echartsOptions.title,
          text: this.chartTitle,
        },
        // @ts-ignore
        series: [...this.parsedDataResource(this.dataSource)],
      };
    }
  }

  ngOnInit() {
    this.echartsOptions = {
      title: {
        text: this.chartTitle,
        top: '30px',
        textStyle: {
          color: this.chartOptions.textColor ?? '#FFF',
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: `{a}: {c}`,
        padding: 5,
        backgroundColor: '#000',
        textStyle: {
          color: this.chartOptions.textColor ?? COLOR.WHITE,
        },
      },
      legend: {
        show: this.chartOptions?.showLegend ?? true,
        orient: this.chartOptions?.legendOrientation
          ? this.chartOptions.legendOrientation
          : 'horizontal',
        top: '90px',
        left: '12px',
        icon: 'circle',
        type: this.chartOptions.legendScroll ? LEGEND_TYPE.SCROLL : LEGEND_TYPE.PLAIN,
        textStyle: {
          color: this.chartOptions.textColor ?? COLOR.WHITE,
          fontSize: '10px',
        },
        pageTextStyle: {
          color: COLOR.WHITE
        },
        pageIconColor: COLOR.WHITE
      },
      grid: {
        height: '5px',
        left: 8,
      },
      xAxis: {
        show: false,
        type: 'value',
      },
      yAxis: {
        show: false,
        type: 'category',
      },
      // @ts-ignore
      series: [...this.parsedDataResource(this.dataSource)],
    };
  }
  onChartClick(event: any) {}

  parsedDataResource(data: ChartData[]) {
    const tempArr = [];
    for (const item of data) {
      tempArr.push({
        name: item.name,
        type: 'bar',
        stack: 'total',
        data: [item.value],
      });
    }
    return tempArr;
  }
}
