import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMAGE_RESOURCE_TYPES } from 'projects/dashboard-reporting/src/app/shared/constants/index.constants';
import { ImageGalleryOptions } from 'projects/dashboard-reporting/src/app/shared/interfaces';
import { DataResourceList } from 'projects/dashboard-reporting/src/app/shared/interfaces/data-resource.interface';
import { DataResourceService } from 'projects/dashboard-reporting/src/app/shared/services/data-resource/data-resource.service';
import { HelperService } from 'projects/dashboard-reporting/src/app/shared/services/helpers/helper.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent {
  @Input() isVisible: boolean;
  @Output() onModalClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() onImagesSelect: EventEmitter<object> = new EventEmitter<object>();

  constructor(
    private dataResource: DataResourceService,
    public helperService: HelperService
  ) {}

  resourceTypes = IMAGE_RESOURCE_TYPES;
  selectedImages: {
    'report-logo': ImageGalleryOptions;
    'report-cover-image': ImageGalleryOptions;
  } = {
    'report-logo': {
      name: '',
      url: '',
      dataResourceId: '',
    },
    'report-cover-image': {
      name: '',
      url: '',
      dataResourceId: '',
    },
  };
  mediaGallery: {
    'report-logo': Array<ImageGalleryOptions>;
    'report-cover-image': Array<ImageGalleryOptions>;
  } = {
    'report-logo': [],
    'report-cover-image': [],
  };

  ngOnInit() {
    this.extractDataResources('report-cover-image');
    this.extractDataResources('report-logo');
  }

  async extractDataResources(
    resourceType: 'report-logo' | 'report-cover-image'
  ) {
    const dataResourcesList: DataResourceList = await lastValueFrom(
      this.dataResource.getAllDataResources(resourceType, true)
    );
    this.mediaGallery[`${resourceType}`] = [
      ...this.parseDataResources(dataResourcesList.records),
    ];
  }

  parseDataResources(payload: any) {
    const parsedData: Array<{
      url: string;
      name: string;
      dataResourceId: string;
    }> = [];
    for (let file of payload) {
      parsedData.push({
        url: file.storage.files[0].preSignedUrl,
        name: file.storage.files[0].origUploadRelativePath.split('/')[
          file.storage.files[0].origUploadRelativePath.split('/').length - 1
        ],
        dataResourceId: file._id,
      });
    }
    return parsedData;
  }

  selectImage(
    imgEntity: { url: string; name: string; dataResourceId: string },
    imageType: any
  ) {
    this.selectedImages = {
      ...this.selectedImages,
      [imageType]: imgEntity,
    };
  }

  closeModal() {
    this.onModalClose.emit();
  }

  onImageSelection() {
    this.closeModal();
    this.onImagesSelect.emit(this.selectedImages);
  }
}
