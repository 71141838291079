import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LabelsMap } from '../../interfaces';
import { Routes } from '../routes/api-routes';

@Injectable({
  providedIn: 'root',
})
export class LabelsMapService {
  _labelMap!: LabelsMap;

  constructor(private httpClient: HttpClient) {
    this.populateLabelMap();
  }

  async getLabelName(id: string) {
    if (this._labelMap) {
      return this._labelMap?.[id] ? this._labelMap[id]?.name : '';
    } else {
      await this.populateLabelMap();
    }
    return '';
  }

  async populateLabelMap() {
    this._labelMap = await lastValueFrom(
      this.httpClient.get<LabelsMap>(Routes.LABELS_MAP).pipe(),
    );
  }

  getAllLabels() {
    return this.httpClient.get<LabelsMap>(Routes.LABELS_MAP).pipe();
  }
}
