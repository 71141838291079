import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() totalRecords?: number = 0;
  @Input() pageSize: number = 10;
  @Input() maxVisiblePages: number = 5;
  @Input() currentPage: number = 1;

  @Output() pageChange = new EventEmitter<number>();

  get totalPages(): number {
    return Math.ceil((this.totalRecords ?? 1) / this.pageSize);
  }

  get visiblePages(): (number | '...')[] {
    const pages: (number | '...')[] = [];
    const totalVisiblePages = Math.min(this.maxVisiblePages, this.totalPages);

    if (this.totalPages <= this.maxVisiblePages) {
      return Array.from({ length: this.totalPages }, (_, index) => index + 1);
    }

    if (this.currentPage <= Math.ceil(this.maxVisiblePages / 2)) {
      pages.push(
        ...Array.from({ length: totalVisiblePages }, (_, index) => index + 1),
      );
      pages.push('...');
      pages.push(this.totalPages);
    } else if (
      this.currentPage >=
      this.totalPages - Math.floor(this.maxVisiblePages / 2)
    ) {
      pages.push(1, '...');
      pages.push(
        ...Array.from(
          { length: totalVisiblePages },
          (_, index) => this.totalPages - totalVisiblePages + index + 1,
        ),
      );
    } else {
      const leftOffset = Math.floor((totalVisiblePages - 3) / 2);
      const rightOffset = totalVisiblePages - 3 - leftOffset;

      pages.push(1, '...');
      pages.push(
        ...Array.from(
          { length: leftOffset },
          (_, index) => this.currentPage - leftOffset + index,
        ),
      );
      pages.push(this.currentPage);
      pages.push(
        ...Array.from(
          { length: rightOffset },
          (_, index) => this.currentPage + index + 1,
        ),
      );
      pages.push('...', this.totalPages);
    }

    return pages;
  }

  goToPage(page: number): void {
    if (
      typeof page === 'number' &&
      page !== this.currentPage &&
      page >= 1 &&
      page <= this.totalPages
    ) {
      this.pageChange.emit(page);
    }
  }

  goToFirstPage(): void {
    this.goToPage(1);
  }

  goToLastPage(): void {
    this.goToPage(this.totalPages);
  }

  incrementPage(): void {
    if (this.currentPage < this.totalPages) {
      this.goToPage(this.currentPage + 1);
    }
  }

  decrementPage(): void {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  }

  getMinValue(value1: number, value2?: number) {
    return Math.min(value1, value2 ?? 0);
  }
}
