import { LegendComponentOption } from 'echarts';
import { ChartData, PieChartOptions } from '../interfaces';
import { CHART_TOOLTIP } from '../constants/charts.constants';

export const getLegendRightConfigForCharts = (
  options: PieChartOptions,
  data: ChartData[],
): LegendComponentOption => {
  return {
    orient: 'vertical',
    right: '10px',
    top: 'center',
    type: 'scroll',
    itemWidth: 12,
    itemHeight: 12,
    textStyle: {
      width: 110,
      color: options.textColor ?? '#FFF',
      rich: {
        a: { align: 'left' },
        b: { align: 'right' },
      },
    },
    icon: 'circle',
    formatter: (name: string) => {
      // Retrieve the data of the legend item based on its name
      const itemData = data?.find((series) => series.name === name);

      return `{a|${name}} {b|${itemData?.value}}`;
    },
  };
};

export const getTooltipConfigsForCharts = (options: PieChartOptions) => {
    if (options.tooltipText === CHART_TOOLTIP.NAME_WITH_PERCENTAGE) {
      return '{b} {d}%';
    } else {
      return `${options.tooltipText} {c}`;
    }
};
