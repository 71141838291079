import { Component, Input } from '@angular/core';
import {
  FindingsDetailsEntity,
  LabelsMap,
  MissionDetails,
  RelatedEntities,
} from 'projects/dashboard-reporting/src/app/shared/interfaces';
import { HelperService } from 'projects/dashboard-reporting/src/app/shared/services/helpers/helper.service';
import * as moment from 'moment';

@Component({
  selector: 'findings-detail',
  templateUrl: './findings-details-page.component.html',
  styleUrls: ['./findings-details-page.component.scss'],
})
export class FindingsDetailsPageComponent {
  @Input() findingDetails!: FindingsDetailsEntity;
  @Input() relatedEntitiesMap: RelatedEntities = {
    assetsMap: {},
    componentsMap: {},
    dataResourcesMap: {},
    sitesMap: {},
  };
  @Input() pageNumber = 1;
  @Input() totalPages = 10;
  @Input() missionDetails!: MissionDetails;
  @Input() itemIndex = 0;
  @Input() labelMap!: LabelsMap;
  constructor(public helperService: HelperService) {}

  getDataFromSiteMap(key: string, id?: string) {
    if (this.relatedEntitiesMap && id) {
      const respectedItem: any =
        this.relatedEntitiesMap[key as keyof RelatedEntities];
      return respectedItem[id];
    }
    return '';
  }

  getParsedDate(date?: string) {
    return (
      date?.length && moment.utc(date).format('DD MMM YYYY (HHmm[HRS] [GMT]Z)')
    );
  }

  getLabelName(id: string) {
    return this.labelMap?.[id]?.name ?? '';
  }

  getFindingLocation(findingDetails: FindingsDetailsEntity) {
    return `${this.formatLocation(findingDetails, 'gpsLatitude') ?? 'N/A'},
        ${this.formatLocation(findingDetails, 'gpsLongitude') ?? 'N/A'}`;
  }

  formatLocation(findingDetails: FindingsDetailsEntity, key: string) {
    return findingDetails.annotations[0]?.dataResourceId
      ? `${this.helperService.decimalToDMS(
          this.getDataFromSiteMap(
            'dataResourcesMap',
            findingDetails.annotations[0]?.dataResourceId
          )?.metadata?.exif?.[key]
        )} ${
          this.getDataFromSiteMap(
            'dataResourcesMap',
            findingDetails.annotations[0]?.dataResourceId
          )?.metadata?.exif?.[key + 'Ref']
        }`
      : null;
  }
}
