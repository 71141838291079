import { Component, Input, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ChartData, PieChartOptions } from '../../../interfaces';
import { getLegendRightConfigForCharts, getTooltipConfigsForCharts } from '../../../utils/chart-helpers';

@Component({
  selector: 'pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent {
  echartsOptions: EChartsOption = {};
  @Input() dataSource: ChartData[] = [];
  @Input() chartOptions!: PieChartOptions;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['dataSource'] &&
      !changes['dataSource'].firstChange
    ) {
      this.echartsOptions = {
        ...this.echartsOptions,
        legend: {
          ...this.echartsOptions.legend,
          ...getLegendRightConfigForCharts(this.chartOptions, this.dataSource)
        },
        series: [
          {
            // @ts-ignore
            ...this.echartsOptions.series[0],
            data: [...changes['dataSource'].currentValue],
          },
        ],
        title: {
          ...this.echartsOptions.title,
          text: changes['chartTitle']?.currentValue,
        },
      };
    }
  }

  ngOnInit() {
    this.echartsOptions = {
      tooltip: {
        trigger: 'item',
        formatter: getTooltipConfigsForCharts(this.chartOptions),
        padding: 5,
        backgroundColor: '#000',
        textStyle: {
          color: '#FFF',
        },
      },
      grid: {
        top: 10,
      },
      series: [
        {
          top: '0',
          type: 'pie',
          avoidLabelOverlap: false,
          data: this.dataSource,
          selectedMode: 'single',
          labelLine: {
            show: this.chartOptions?.showLabelLine ?? true,
          },
          label: {
            show: false,
          },
        },
      ],
    };
    this.setLegendOptions();
    this.alignChart()
  }
  onChartClick(event: any) {}

  setLegendOptions() {
    if (this.chartOptions?.legendPosition == 'right') {
      this.echartsOptions.legend = {
        ...this.echartsOptions.legend,
        ...getLegendRightConfigForCharts(this.chartOptions, this.dataSource)
      };
    } else {
      this.echartsOptions.legend = {
        ...this.echartsOptions.legend,
        orient: 'horizontal',
        bottom: '10px',
        icon: 'circle',
        type: 'scroll',
        textStyle: {
          color: this.chartOptions.textColor ?? '#FFF',
        },
      };
    }
  }

  alignChart() {
    if (this.chartOptions?.align == 'left') {
      this.echartsOptions = {
        ...this.echartsOptions,
        series: [
          {
            // @ts-ignore
            ...this.echartsOptions.series[0],
            radius: ['0%', '70%'],
            center: ['25%', '50%'],
          },
        ],
        title: {
          ...this.echartsOptions.title,
          left: '22%',
        },
      };
    }
  }
}
