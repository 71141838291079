<div *ngIf="isVisible" class="modal">
  <div class="modal-content">
    <div class="d-flex justify-content-between align-items-center">
      <h2 class="modal-title">{{ modalTitle }}</h2>
      <span class="close" (click)="onClose()">
        <img
          [src]="'/images/cross-icon.svg' | assetUrl"
          alt="search-icon"
          class="search-icon"
        />
      </span>
    </div>
    <ng-content></ng-content>
  </div>
</div>
