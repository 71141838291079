import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChartData, ReportQueryFilters } from '../../interfaces';
import { FindingsByPriority } from '../../interfaces/chart-api.interface';
import { HelperService } from '../helpers/helper.service';
import { Routes } from '../routes/api-routes';

@Injectable({
  providedIn: 'root',
})
export class FindingsChartService {
  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService
  ) {}

  getFindingsData(filters?: ReportQueryFilters) {
    return this.httpClient
      .post<FindingsByPriority[]>(Routes.FINDINGS_BY_SEVERITY, {
        ...this.helperService.filterObject(filters),
      })
      .pipe();
  }

  getFindingsByMonth(filters?: ReportQueryFilters) {
    return this.httpClient
      .post<ChartData[]>(Routes.FINDINGS_STATS_BY_MONTH, {
        ...this.helperService.filterObject(filters),
      })
      .pipe();
  }

  getStatsByAsset(hierarchyLevelName: string,filters?: ReportQueryFilters) {
    return this.httpClient
      .post<ChartData[]>(Routes.FINDINGS_STATS_BY_ASSET, {
        hierarchyLevelName,
        ...this.helperService.filterObject(filters),
      })
      .pipe();
  }

  geStatsBySite(filters?: ReportQueryFilters) {
    return this.httpClient
      .post<ChartData[]>(Routes.FINDINGS_STATS_BY_SITE, {
        ...this.helperService.filterObject(filters),
      })
      .pipe();
  }

  getComponentsStats(filters?: ReportQueryFilters) {
    return this.httpClient
      .post<ChartData[]>(Routes.STATS_BY_COMPONENTS, {
        ...this.helperService.filterObject(filters),
      })
      .pipe();
  }
}
