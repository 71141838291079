(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@app/utility"), require("fe-micro-share-components"));
	else if(typeof define === 'function' && define.amd)
		define(["@app/utility", "fe-micro-share-components"], factory);
	else if(typeof exports === 'object')
		exports["dashboard-reporting"] = factory(require("@app/utility"), require("fe-micro-share-components"));
	else
		root["dashboard-reporting"] = factory(root["@app/utility"], root["fe-micro-share-components"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__94228__, __WEBPACK_EXTERNAL_MODULE__13423__) => {
return 