import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CheckboxOptionsPayload } from 'projects/dashboard-reporting/src/app/shared/interfaces';

@Component({
  selector: 'searchable-checkbox-group',
  templateUrl: './searchable-checkbox-group.component.html',
  styleUrls: ['./searchable-checkbox-group.component.scss'],
})
export class SearchableChecboxGroupComponent {
  @Input() options: CheckboxOptionsPayload[] = [];
  @Input() heading = '';
  @Output() selectedItems: EventEmitter<any> = new EventEmitter();
  selectAll = true;
  searchedQuery = '';

  onCheckboxChange() {
    if (this.getSelectedItems().length != this.options.filter((item:CheckboxOptionsPayload)=>item.hasFindings).length) {
      this.selectAll = false;
    } else {
      this.selectAll = true;
    }
    this.selectedItems.emit(this.getSelectedItems());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.selectAllOptions();
    }
  }

  selectAllOptions() {
    this.options = this.options.map((option: CheckboxOptionsPayload) => ({
      ...option,
      checked: option.hasFindings,
    }));
  }

  deSelectAllOptions() {
    this.options = this.options.map((option: CheckboxOptionsPayload) => ({
      ...option,
      checked: false,
    }));
  }

  getSelectedItems() {
    return this.options
      .filter(
        (item: CheckboxOptionsPayload) => item.checked && item.hasFindings
      )
      .map(item => item.ids ?? item.id);
  }

  onAllSelect() {
    if (this.selectAll) {
      this.selectAllOptions();
    } else {
      this.deSelectAllOptions();
    }
    this.selectedItems.emit(this.getSelectedItems());
  }

  onSearch(event: Event) {
    this.searchedQuery = (event.target as HTMLInputElement).value;
  }

  getFilteredOptions() {
    return this.options
      .sort((a: CheckboxOptionsPayload, b: CheckboxOptionsPayload) =>
        a.hasFindings && !b.hasFindings ? -1 : 1
      )
      .filter(item =>
        item.name
          .toLocaleLowerCase()
          .includes(this.searchedQuery.toLocaleLowerCase())
      );
  }
}
